import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import baseUrl from "../Helper/config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Loading from "../Components/Loading";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
const libraries = ["places"];
const deliveryMethods = [
  {
    id: 1,
    title: "Point to point",
    turnaround: "Pick-up & drop-off location required.",
    price: "Based on miles",
  },
  {
    id: 2,
    title: "Hourly",
    turnaround: "Only pick-up location is required",
    price: "Min. 3 hours",
  },
];

function Cars() {
  const navigate = useNavigate();
  const dateInputRef = useRef(null);
  const handleClick = () => {
    dateInputRef.current.showPicker();
  };
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const [vehicleCat, setVehicleCat] = useState([
    {
      catName: "Sedan",
      catImg:
        "https://res.cloudinary.com/dcfotquxt/image/upload/v1730910305/Orienty%20Black%20Car%20Service/VehicleCategories/sedan_rnh1zq.png",
      catID: 1564165468,
    },
    {
      catName: "Minivan",
      catImg:
        "https://res.cloudinary.com/dcfotquxt/image/upload/v1730910305/Orienty%20Black%20Car%20Service/VehicleCategories/minivan_wsmard.png",
      catID: 7894654655,
    },
    {
      catName: "SUV",
      catImg:
        "https://res.cloudinary.com/dcfotquxt/image/upload/v1730910355/Orienty%20Black%20Car%20Service/VehicleCategories/suv_sgzdh3.png",
      catID: 48946131316,
    },
  ]);
  const [pickUpAddressAllData, setPickUpAddressAllData] = useState({});
  const [pickUpAddress, setPickUpAddress] = useState("");
  const searchBoxPickUpRef = useRef(null);
  const handlePickUpPlaceChanged = () => {
    const place = searchBoxPickUpRef.current.getPlaces()[0];
    setPickUpAddressAllData(place);
    if (place) {
      setPickUpAddress(place.formatted_address || place.name);
    }
  };
  const [dropOffAddressAllData, setDropOffAddressAllData] = useState({});
  const [dropOffAddress, setDropOffAddress] = useState("");
  const searchBoxDropOffRef = useRef(null);
  const handleDropOffPlaceChanged = () => {
    const place = searchBoxDropOffRef.current.getPlaces()[0];
    setDropOffAddressAllData(place);
    if (place) {
      setDropOffAddress(place.formatted_address || place.name);
    }
  };

  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);
  const [showDate, setShowDate] = useState(today.toLocaleDateString("en-CA"));
  const [pickUpDate, setPickUpDate] = useState(formattedDate);
  const [pickUpTime, setPickupTime] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [carsData, setCarsData] = useState([]);
  const [rideType, setRideType] = useState({});
  const [rideHours, setRideHours] = useState(0);

  useEffect(() => {
    if (!location.userData) {
      const storedData = localStorage.getItem("__OrientyBlackCarServiceData__");
      if (storedData) {
        var savedData = JSON.parse(storedData);
        setPickUpAddress(savedData?.userData?.pickUpAddress);
        setDropOffAddress(savedData?.userData?.dropOffAddress);
        setPickUpDate(savedData?.userData?.pickUpDate);
        const savedDate = new Date(savedData?.userData?.pickUpDate);
        setShowDate(savedDate.toISOString().split("T")[0]);
        setPickupTime(savedData?.userData?.pickUpTime);
        setVehicleType(savedData?.userData?.vehicleType);
        setCarsData(savedData?.cars);
        setPickUpAddressAllData(savedData?.userData?.pickUpAddressAllData);
        setDropOffAddressAllData(savedData?.userData?.dropOffAddressAllData);
        setRideType(savedData?.userData?.rideType);
        setRideHours(savedData?.userData?.rideHours);
      }
    }
  }, []);

  const handleRideData = () => {
    if (rideType?.title === "Point to point") {
      if (!pickUpAddress || !dropOffAddress || !pickUpDate || !pickUpTime) {
        toast.warn("Please fill all the details.");
      } else {
        setLoading(true);
        var userData = {
          pickUpAddress,
          dropOffAddress,
          pickUpDate,
          pickUpTime,
          pickUpAddressAllData,
          dropOffAddressAllData,
          rideType,
        };

        let config = {
          method: "post",
          url: `${baseUrl}/v1/calculatePrice`,
          data: userData,
        };

        axios
          .request(config)
          .then((response) => {
            setTimeout(() => {
              setLoading(false);
              if (response?.data?.success == false) {
                toast.warn("Error!! Please try changing the locations.");
              } else {
                localStorage.setItem(
                  "__OrientyBlackCarServiceData__",
                  JSON.stringify({ cars: response.data, userData })
                );
                setCarsData(response?.data);
              }
            }, 1000);
          })
          .catch((error) => {
            setLoading(false);
            toast.warn("Error!! Please try again later.");
          });
      }
    } else if (rideType?.title === "Hourly") {
      if (
        !pickUpAddress ||
        !rideHours ||
        rideHours < 3 ||
        !pickUpDate ||
        !pickUpTime
      ) {
        toast.warn("Please fill all the details or ride hours more than 3.");
      } else {
        setLoading(true);
        var userData = {
          pickUpAddress,
          dropOffAddress,
          pickUpDate,
          pickUpTime,
          pickUpAddressAllData,
          dropOffAddressAllData,
          rideType,
          rideHours,
        };

        let config = {
          method: "post",
          url: `${baseUrl}/v1/calculatePrice`,
          data: userData,
        };

        axios
          .request(config)
          .then((response) => {
            setTimeout(() => {
              setLoading(false);
              if (response?.data?.success == false) {
                toast.warn("Error!! Please try changing the locations.");
              } else {
                localStorage.setItem(
                  "__OrientyBlackCarServiceData__",
                  JSON.stringify({ cars: response.data, userData })
                );
                setCarsData(response?.data);
              }
            }, 1000);
          })
          .catch((error) => {
            setLoading(false);
            toast.warn("Error!! Please try again later.");
          });
      }
    }
  };

  const bookRide = (car) => {
    var userData = {
      vehicleType,
      pickUpAddress,
      dropOffAddress,
      pickUpDate,
      pickUpTime,
      rideType,
      rideHours,
    };
    localStorage.setItem(
      "__OrientyBlackCarServiceCarData__",
      JSON.stringify({ car, userData })
    );
    navigate("/car", { car, userData });
    window.location.reload();
  };
  useEffect(() => {
    document.title = "Reliable Chauffeured Car Services NYC";
  }, []);

  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <div className="no-bottom no-top zebra" id="content">
        <div id="top"></div>

        <section id="subheader" className="jarallax text-light">
          <img
            src="images/background/2.jpg"
            className="jarallax-img"
            alt="usiness transportation New York"
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Cars</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-cars" style={{ padding: 0 }}>
          <section
            id="section-hero"
            aria-label="section"
            className="no-top sm-mt-0 pb-4"
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="spacer-single sm-hide"></div>
                  <div className="p-4 rounded-3" data-bgcolor="#ffffff">
                    <div name="contactForm" id="contact_form">
                      <div id="step-1" className="row">
                        <div className="col-lg-6 mb30">
                          <h5>
                            Available vehicle types:&nbsp;
                            <span
                              style={{
                                color: "#169511",
                              }}
                            >
                              {vehicleType}
                            </span>
                          </h5>

                          <div className="de_form de_radio row g-3">
                            {vehicleCat?.map((vehicle, i) => (
                              <div
                                className="radio-img col-lg-4 col-sm-4 col-6"
                                key={i}
                              >
                                <input
                                  id={`radio-${i}`}
                                  name="Car_Type"
                                  type="radio"
                                  value={vehicle?.catName}
                                  onChange={(e) => {
                                    setVehicleType(e.target.value);
                                  }}
                                />
                                <label htmlFor={`radio-${i}`}>
                                  <img
                                    src={vehicle?.catImg}
                                    alt="Professional drivers in New York City"
                                  />
                                  {vehicle?.catName}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="row">
                            <h5>Select ride type:&nbsp;</h5>
                            <div
                              className="de_form de_radio row g-3"
                              style={{
                                margin: 0,
                              }}
                            >
                              {deliveryMethods?.map((method, i) => (
                                <div
                                  className="radio-img col-lg-6 col-sm-6 col-6"
                                  key={i}
                                  style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    margin: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      border: "1px solid #1ecb15",
                                      borderRadius: 4,
                                      padding: 4,
                                      paddingLeft: 8,
                                      cursor: "pointer",
                                      fontWeight: "500",
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 8,
                                      backgroundColor:
                                        rideType?.title == method?.title
                                          ? "#1ecb15"
                                          : "#fff",
                                    }}
                                    onClick={() => {
                                      setRideType(method);
                                      setCarsData([]);
                                    }}
                                  >
                                    <i
                                      className="fa fa-map-pin fa-md"
                                      style={{
                                        color:
                                          rideType?.title == method?.title
                                            ? "#fff"
                                            : "#1ecb15",
                                        paddingTop: 5,
                                      }}
                                    ></i>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color:
                                            rideType?.title == method?.title
                                              ? "#fff"
                                              : "#1ecb15",
                                        }}
                                      >
                                        {method?.title}
                                      </span>
                                      <span
                                        style={{
                                          color:
                                            rideType?.title == method?.title
                                              ? "#fff"
                                              : "#1ecb15",
                                          fontSize: 12,
                                          marginTop: 0,
                                          lineHeight: 1,
                                        }}
                                      >
                                        {method?.turnaround}
                                      </span>
                                      <span
                                        style={{
                                          color:
                                            rideType?.title == method?.title
                                              ? "#fff"
                                              : "#1ecb15",
                                          fontSize: 14,
                                          fontWeight: 800,
                                          marginTop: 5,
                                        }}
                                      >
                                        {method?.price}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div className="spacer-20"></div>
                            <div className="col-lg-6 mb20">
                              <h5>Pick Up Location</h5>
                              <LoadScript
                                googleMapsApiKey="AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI"
                                libraries={libraries}
                              >
                                <StandaloneSearchBox
                                  onLoad={(ref) =>
                                    (searchBoxPickUpRef.current = ref)
                                  }
                                  onPlacesChanged={handlePickUpPlaceChanged}
                                >
                                  <input
                                    type="text"
                                    placeholder="Enter address"
                                    value={pickUpAddress}
                                    className="form-control"
                                    onChange={(e) =>
                                      setPickUpAddress(e.target.value)
                                    }
                                    style={{
                                      boxSizing: `border-box`,
                                      border: `1px solid #031b4e80`,
                                      width: `100%`,
                                      height: `40px`,
                                      padding: `0 12px`,
                                      borderRadius: `3px`,
                                      fontSize: `16px`,
                                      outline: `none`,
                                      textOverflow: `ellipses`,
                                    }}
                                  />
                                </StandaloneSearchBox>
                              </LoadScript>

                              <div className="jls-address-preview jls-address-preview--hidden">
                                <div className="jls-address-preview__header"></div>
                              </div>
                            </div>

                            <div
                              className={
                                rideType?.title === "Hourly"
                                  ? `col-lg-6 mb20`
                                  : `d-none`
                              }
                            >
                              <h5>Booking for hours (min 3hrs.)</h5>
                              <div className="mt-2">
                                <input
                                  id="rideHours"
                                  type="number"
                                  name="rideHours"
                                  value={rideHours}
                                  onChange={(e) => {
                                    setRideHours(e.target.value);
                                  }}
                                  placeholder="Ride hours"
                                  style={{
                                    boxSizing: `border-box`,
                                    border: `1px solid #031b4e80`,
                                    width: `100%`,
                                    height: `40px`,
                                    padding: `0 12px`,
                                    borderRadius: `3px`,
                                    fontSize: `16px`,
                                    outline: `none`,
                                    textOverflow: `ellipses`,
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              className={
                                rideType?.title === "Point to point"
                                  ? `col-lg-6 mb20`
                                  : `d-none`
                              }
                            >
                              <h5>Drop Off Location</h5>
                              <LoadScript
                                googleMapsApiKey="AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI"
                                libraries={libraries}
                              >
                                <StandaloneSearchBox
                                  onLoad={(ref) =>
                                    (searchBoxDropOffRef.current = ref)
                                  }
                                  onPlacesChanged={handleDropOffPlaceChanged}
                                >
                                  <input
                                    type="text"
                                    placeholder="Enter address"
                                    value={dropOffAddress}
                                    className="form-control"
                                    onChange={(e) =>
                                      setDropOffAddress(e.target.value)
                                    }
                                    style={{
                                      boxSizing: `border-box`,
                                      border: `1px solid #031b4e80`,
                                      width: `100%`,
                                      height: `40px`,
                                      padding: `0 12px`,
                                      borderRadius: `3px`,
                                      fontSize: `16px`,
                                      outline: `none`,
                                      textOverflow: `ellipses`,
                                    }}
                                  />
                                </StandaloneSearchBox>
                              </LoadScript>

                              <div className="jls-address-preview jls-address-preview--hidden">
                                <div className="jls-address-preview__header"></div>
                              </div>
                            </div>

                            <div className="col-lg-12 mb20">
                              <h5>Pick Up Date & Time</h5>
                              <div className="date-time-field">
                                <input
                                  type="date"
                                  name="Pick Up Date"
                                  value={showDate}
                                  style={{
                                    fontWeight: 400,
                                    lineHeight: 1.6,
                                    border: "1px solid #031b4e80",
                                    borderRadius: "3px",
                                    height: "40px",
                                    width: "48%",
                                    paddingLeft: 12,
                                    color: "#4f4f4f",
                                  }}
                                  ref={dateInputRef}
                                  onClick={handleClick}
                                  onChange={(e) => {
                                    const [year, month, day] =
                                      e.target.value.split("-");
                                    const date = new Date(year, month - 1, day);
                                    const formattedDate =
                                      date.toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit",
                                      });
                                    setShowDate(e.target.value);
                                    setPickUpDate(formattedDate);
                                  }}
                                />

                                <select
                                  name="Pick Up Time"
                                  id="pickup-time"
                                  value={pickUpTime}
                                  style={{
                                    fontWeight: 400,
                                    lineHeight: 1.6,
                                    border: "1px solid #031b4e80",
                                    borderRadius: "3px",
                                    height: "40px",
                                    width: "48%",
                                    paddingLeft: 12,
                                    color: "#4f4f4f",
                                  }}
                                  onChange={(e) => {
                                    setPickupTime(e.target.value);
                                  }}
                                >
                                  <option selected disabled value="Select time">
                                    Time
                                  </option>
                                  <option value="00:00 AM">00:00 AM</option>
                                  <option value="00:30 AM">00:30 AM</option>
                                  <option value="01:00 AM">01:00 AM</option>
                                  <option value="01:30 AM">01:30 AM</option>
                                  <option value="02:00 AM">02:00 AM</option>
                                  <option value="02:30 AM">02:30 AM</option>
                                  <option value="03:00 AM">03:00 AM</option>
                                  <option value="03:30 AM">03:30 AM</option>
                                  <option value="04:00 AM">04:00 AM</option>
                                  <option value="04:30 AM">04:30 AM</option>
                                  <option value="05:00 AM">05:00 AM</option>
                                  <option value="05:30 AM">05:30 AM</option>
                                  <option value="06:00 AM">06:00 AM</option>
                                  <option value="06:30 AM">06:30 AM</option>
                                  <option value="07:00 AM">07:00 AM</option>
                                  <option value="07:30 AM">07:30 AM</option>
                                  <option value="08:00 AM">08:00 AM</option>
                                  <option value="08:30 AM">08:30 AM</option>
                                  <option value="09:00 AM">09:00 AM</option>
                                  <option value="09:30 AM">09:30 AM</option>
                                  <option value="10:00 AM">10:00 AM</option>
                                  <option value="10:30 AM">10:30 AM</option>
                                  <option value="11:00 AM">11:00 AM</option>
                                  <option value="11:30 AM">11:30 AM</option>
                                  <option value="12:00 PM">12:00 PM</option>
                                  <option value="12:30 PM">12:30 PM</option>
                                  <option value="01:00 PM">01:00 PM</option>
                                  <option value="01:30 PM">01:30 PM</option>
                                  <option value="02:00 PM">02:00 PM</option>
                                  <option value="02:30 PM">02:30 PM</option>
                                  <option value="03:00 PM">03:00 PM</option>
                                  <option value="03:30 PM">03:30 PM</option>
                                  <option value="04:00 PM">04:00 PM</option>
                                  <option value="04:30 PM">04:30 PM</option>
                                  <option value="05:00 PM">05:00 PM</option>
                                  <option value="05:30 PM">05:30 PM</option>
                                  <option value="06:00 PM">06:00 PM</option>
                                  <option value="06:30 PM">06:30 PM</option>
                                  <option value="07:00 PM">07:00 PM</option>
                                  <option value="07:30 PM">07:30 PM</option>
                                  <option value="08:00 PM">08:00 PM</option>
                                  <option value="08:30 PM">08:30 PM</option>
                                  <option value="09:00 PM">09:00 PM</option>
                                  <option value="09:30 PM">09:30 PM</option>
                                  <option value="10:00 PM">10:00 PM</option>
                                  <option value="10:30 PM">11:30 PM</option>
                                  <option value="11:00 PM">11:00 PM</option>
                                  <option value="11:30 PM">12:30 PM</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <button
                            id="send_message"
                            value="Find a Vehicle"
                            className="btn-main pull-right"
                            onClick={handleRideData}
                          >
                            Find Ride
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  {carsData?.map((car, i) => {
                    return (
                      <div className="col-lg-12" key={i}>
                        <div className="de-item-list mb30">
                          <div className="d-img">
                            <img
                              src={car?.img}
                              className="img-fluid"
                              alt="Luxury rides for NYC city tours"
                            />
                          </div>
                          <div className="d-info">
                            <div className="d-text">
                              <h4>{car?.name}</h4>
                              <div className="d-atr-group">
                                <ul className="d-atr">
                                  {car?.specifications?.length > 0
                                    ? car?.specifications?.map((spec, j) => {
                                        return (
                                          <li key={j}>
                                            <span>{spec?.name}:</span>
                                            {spec?.value}
                                          </li>
                                        );
                                      })
                                    : null}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="d-price">
                            <span>${car?.price}</span>
                            <p
                              style={{
                                fontSize: 12,
                              }}
                            >
                              Tax: ${car?.taxCharged}
                            </p>
                            <button
                              className="btn-main"
                              onClick={() => {
                                bookRide(car);
                              }}
                            >
                              Book Now
                            </button>
                            <button
                              className="btn-main bg-warning"
                              style={{
                                marginLeft: 4,
                              }}
                              onClick={() => {
                                bookRide(car);
                              }}
                            >
                              Request Quote
                            </button>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer />
    </>
  );
}

export default Cars;
