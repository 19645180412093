import React from "react";

function Footer() {
  return (
    <footer className="text-light">
      <div className="container">
        <div className="row g-custom-x">
          <div className="col-lg-3">
            <div className="widget">
              <img
                className="logo-1 mb-3"
                src="https://res.cloudinary.com/dcfotquxt/image/upload/e_colorize:100,co_white/v1726066341/Orienty%20Black%20Car%20Service/Logos/transparent-file-thumb_gk3aze.png"
                alt="Vehicle Chauffeur Car Service New York"
              />
              <h5>Orienty Black Car Service</h5>
              <p>
                Where quality meets affordability. We understand the importance
                of a smooth and enjoyable journey without the burden of
                excessive costs. That's why we have meticulously crafted our
                offerings to provide you with top-notch vehicles at minimum
                expense.
              </p>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="widget">
              <h5>Contact Info</h5>
              <address className="s1">
                <span>
                  <i className="id-color fa fa-map-marker fa-lg"></i>38 Vincent
                  Road Hicksille NY 11801
                </span>
                <span>
                  <i className="id-color fa fa-phone fa-lg"></i>+1(680)346-6020
                </span>
                <span>
                  <i className="id-color fa fa-phone fa-lg"></i>+1(347)894-7091
                </span>

                <span>
                  <i className="id-color fa fa-envelope-o fa-lg"></i>
                  <a href="mailto:orientyblackcarservice@gmail.com">
                    orientyblackcarservice@gmail.com
                  </a>
                </span>
                <span>
                  <i className="id-color fa fa-envelope-o fa-lg"></i>
                  <a href="mailto:contact@orientyblackcarservice.com">
                    contact@orientyblackcarservice.com
                  </a>
                </span>
              </address>
            </div>
          </div>

          <div className="col-lg-3">
            <h5>Quick Links</h5>
            <div className="row">
              <div className="col-lg-6">
                <div className="widget">
                  <ul>
                    <li>
                      <a href="about">About</a>
                    </li>
                    <li>
                      <a href="contact">Contact</a>
                    </li>
                    <li>
                      <a href="terms">Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="privacy">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="widget">
              <h5>Social Network</h5>
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/share/15BahL7fmp/?mibextid=LQQJ4d"
                  target="_blank"
                >
                  <i className="fa fa-facebook fa-lg"></i>
                </a>
                <a
                  href="https://www.instagram.com/orientyblackcar/"
                  target="_blank"
                >
                  <i className="fa fa-instagram fa-lg"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex">
                <div className="de-flex-col">
                  <span href="index.html">
                    Copyright 2024 - Orienty Black Car Service by&nbsp;
                    <a
                      href="www.logsboot.com"
                      target="_blank"
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      LogsBoot Technology Leader
                    </a>
                  </span>
                </div>
                {/* <ul className="menu-simple">
                  <li>
                    <a href="#">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
