import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Loading from "../Components/Loading";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../Helper/config";

const secretKey = process.env.REACT_APP_JWT_SECRET;

function Register() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Experience NYC with Premium Chauffeured Cars";
  }, []);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const registerUser = (e) => {
    if (!userData?.name || !userData?.email || !userData.password) {
      toast.warn("Error! Please fill all the fields.");
    } else {
      setLoading(true);
      const encrypted = CryptoJS.AES.encrypt(
        userData?.password,
        process.env.REACT_APP_JWT_SECRET
      ).toString();
      let data = JSON.stringify({ ...userData, password: encrypted });

      let config = {
        method: "post",
        url: `${baseUrl}/v1/register`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          if (response?.data?.success) {
            localStorage.setItem(
              "__OrientyBlackCarService__",
              JSON.stringify(response?.data)
            );
            navigate("/dashboard");
            window.location.reload();
          } else {
            toast.warn("Already registered with give email.");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.warn("Error! Please try again later.");
        });
    }
  };

  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        <section id="subheader" className="jarallax text-light">
          <img
            src="images/background/subheader.jpg"
            className="jarallax-img"
            alt="Fleet car Chauffeur NYC"
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Register</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section aria-label="section">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h3>Don't have an account? Register now.</h3>
                <p>
                  By registering, users enhance their car booking experience and
                  get the most out of the service.
                </p>

                <div className="spacer-10"></div>

                <div
                  name="contactForm"
                  id="contact_form"
                  className="form-border"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="field-set">
                        <label>Name:</label>
                        <input
                          type="text"
                          name="name"
                          value={userData.name}
                          onChange={handleChange}
                          id="name"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="field-set">
                        <label>Email Address:</label>
                        <input
                          type="text"
                          name="email"
                          value={userData.email}
                          onChange={handleChange}
                          id="email"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="field-set">
                        <label>Phone:</label>
                        <input
                          type="text"
                          name="phone"
                          value={userData.phone}
                          onChange={handleChange}
                          id="phone"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="field-set">
                        <label>Password:</label>
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          name="password"
                          value={userData.password}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <p>
                      By clicking "Register Now" I acknowledge that I have read,
                      understood, and agree to the{" "}
                      <a href="/terms">Terms & Conditions.</a>
                    </p>

                    <div className="col-md-12">
                      <div id="submit">
                        <button
                          id="send_message"
                          className="btn-main color-2 mt-3"
                          onClick={registerUser}
                        >
                          Register Now
                        </button>
                      </div>

                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer />
    </>
  );
}

export default Register;
