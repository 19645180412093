import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Loading from "../Components/Loading";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import baseUrl from "../Helper/config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const libraries = ["places"];

const deliveryMethods = [
  {
    id: 1,
    title: "Point to point",
    turnaround: "Pick-up & drop-off location required.",
    price: "Based on miles",
  },
  {
    id: 2,
    title: "Hourly",
    turnaround: "Only pick-up location is required",
    price: "Min. 3 hours",
  },
];

function Home() {
  const dateInputRef = useRef(null);
  const handleClick = () => {
    dateInputRef.current.showPicker();
  };
  const navigate = useNavigate();
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
    deliveryMethods[0]
  );
  const [loading, setLoading] = useState(false);
  const [vehicleCat, setVehicleCat] = useState([
    {
      catName: "Sedan",
      catImg:
        "https://res.cloudinary.com/dcfotquxt/image/upload/v1730910305/Orienty%20Black%20Car%20Service/VehicleCategories/sedan_rnh1zq.png",
      catID: 1564165468,
    },
    {
      catName: "Minivan",
      catImg:
        "https://res.cloudinary.com/dcfotquxt/image/upload/v1730910305/Orienty%20Black%20Car%20Service/VehicleCategories/minivan_wsmard.png",
      catID: 7894654655,
    },
    {
      catName: "SUV",
      catImg:
        "https://res.cloudinary.com/dcfotquxt/image/upload/v1730910355/Orienty%20Black%20Car%20Service/VehicleCategories/suv_sgzdh3.png",
      catID: 48946131316,
    },
  ]);
  const [rideType, setRideType] = useState({
    id: 1,
    title: "Point to point",
    turnaround: "Pick-up and drop off location required.",
    price: "Based on miles",
  });
  const [vehicles, setVehicles] = useState([]);
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "post",
      url: `${baseUrl}/v1/vehicles`,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setVehicles(response?.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.title = "Luxury Chauffeured Car in New York";
  }, []);

  const [faqs, setFaqs] = useState([
    {
      faqQ: "How do I get started with Car Booking?",
      faqA: "Getting started with car booking is easy! Simply select your preferred vehicle type, choose the pickup location and date, review the diffrent price as per vehicles and confirm your booking. From there, sit back, relax, and enjoy a seamless experience with our reliable service.",
      faqId: 1,
    },
    {
      faqQ: "Can I book with a debit card?",
      faqA: "Yes, our car services allow you to book with a debit card. However, requirements and policies vary by provider. Some may place a hold on your funds, require additional identification, or have specific restrictions on vehicle types.",
      faqId: 2,
    },
    {
      faqQ: "What kind of Car do I need?",
      faqA: "The type of car you need depends on your travel plans and personal preferences. For solo travel, an economy or compact 'SEDAN' car offers affordability. Families or groups might prefer 'SUVs' or 'MINIVANS' for space and comfort. For special occasions or business trips, consider luxury or premium SUV vehicles. Assess the number of passengers, and desired amenities to choose the best fit.",
      faqId: 3,
    },
    {
      faqQ: "What is a car security deposit?",
      faqA: "A car security deposit is a temporary hold placed on your credit or debit card by our company to cover potential additional charges, such as fuel, tolls, or damages. This deposit serves as a guarantee for the car provider and is typically refunded in some conditions with no additional fees owed.",
      faqId: 4,
    },
    {
      faqQ: "Can I cancel or modify my reservation?",
      faqA: "Yes, we allow you to cancel or modify your reservation. To avoid unexpected charges, review the cancellation and modification terms by contacting us directly if you need assistance with changes.",
      faqId: 5,
    },
  ]);

  const [pickUpAddressAllData, setPickUpAddressAllData] = useState({});
  const [pickUpAddress, setPickUpAddress] = useState("");
  const searchBoxPickUpRef = useRef(null);
  const handlePickUpPlaceChanged = () => {
    const place = searchBoxPickUpRef.current.getPlaces()[0];
    setPickUpAddressAllData(place);
    if (place) {
      setPickUpAddress(place.formatted_address || place.name);
    }
  };
  const [dropOffAddressAllData, setDropOffAddressAllData] = useState({});
  const [dropOffAddress, setDropOffAddress] = useState("");
  const searchBoxDropOffRef = useRef(null);
  const handleDropOffPlaceChanged = () => {
    const place = searchBoxDropOffRef.current.getPlaces()[0];
    setDropOffAddressAllData(place);
    if (place) {
      setDropOffAddress(place.formatted_address || place.name);
    }
  };

  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);
  const [showDate, setShowDate] = useState(today.toLocaleDateString("en-CA"));
  const [pickUpDate, setPickUpDate] = useState(formattedDate);
  const [pickUpTime, setPickupTime] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [rideHours, setRideHours] = useState(3);

  const handleRideData = () => {
    if (rideType?.title === "Point to point") {
      if (!pickUpAddress || !dropOffAddress || !pickUpDate || !pickUpTime) {
        toast.warn("Please fill all the details.");
      } else {
        setLoading(true);
        var userData = {
          pickUpAddress,
          dropOffAddress,
          pickUpDate,
          pickUpTime,
          pickUpAddressAllData,
          dropOffAddressAllData,
          rideType,
        };

        let config = {
          method: "post",
          url: `${baseUrl}/v1/calculatePrice`,
          data: userData,
        };

        axios
          .request(config)
          .then((response) => {
            setTimeout(() => {
              setLoading(false);
              if (response?.data?.success == false) {
                toast.warn("Error!! Please try changing the locations.");
              } else {
                localStorage.setItem(
                  "__OrientyBlackCarServiceData__",
                  JSON.stringify({ cars: response.data, userData })
                );
                navigate("/cars", { cars: response.data, userData });
                window.location.reload();
              }
            }, 1000);
          })
          .catch((error) => {
            setLoading(false);
            toast.warn("Error!! Please try again later.");
          });
      }
    } else if (rideType?.title === "Hourly") {
      if (
        !pickUpAddress ||
        !rideHours ||
        rideHours < 3 ||
        !pickUpDate ||
        !pickUpTime
      ) {
        toast.warn("Please fill all the details or ride hours more than 3.");
      } else {
        setLoading(true);
        var userData = {
          pickUpAddress,
          dropOffAddress,
          pickUpDate,
          pickUpTime,
          pickUpAddressAllData,
          dropOffAddressAllData,
          rideType,
          rideHours,
        };

        let config = {
          method: "post",
          url: `${baseUrl}/v1/calculatePrice`,
          data: userData,
        };

        axios
          .request(config)
          .then((response) => {
            setTimeout(() => {
              setLoading(false);
              if (response?.data?.success == false) {
                toast.warn("Error!! Please try changing the locations.");
              } else {
                localStorage.setItem(
                  "__OrientyBlackCarServiceData__",
                  JSON.stringify({ cars: response.data, userData })
                );
                navigate("/cars", { cars: response.data, userData });
                window.location.reload();
              }
            }, 1000);
          })
          .catch((error) => {
            setLoading(false);
            toast.warn("Error!! Please try again later.");
          });
      }
    }
  };
  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        <section
          id="section-hero"
          aria-label="section"
          className="jarallax full-height vertical-center"
        >
          <img
            src="images/background/1.jpg"
            className="jarallax-img"
            alt="Van Chauffeur New York"
          />
          <div className="container position-relative z1000">
            <div className="row align-items-center">
              <div className="col-lg-6 text-light">
                <h4>
                  <span className="id-color">
                    Fast and Easy Way to Book a Ride
                  </span>
                </h4>
                <div className="spacer-10"></div>
                <h1 className="mb-2">Your Ride, Your Experience</h1>
                <div className="spacer-10"></div>
                <p className="lead">
                  Embark on unforgettable adventures and discover the world in
                  unparalleled comfort and style with our fleet of exceptionally
                  comfortable cars.
                </p>
              </div>

              <div className="col-lg-6 bgcustom">
                <div className="spacer-single sm-hide"></div>
                <div
                  className="p-4 rounded-3 shadow-soft bgcustom"
                  data-bgcolor="#ffffff"
                  style={{
                    backgroundColor: "#fff",
                  }}
                >
                  <div name="contactForm" id="contact_form">
                    <h5>
                      Available vehicle types:&nbsp;
                      <span
                        style={{
                          color: "#169511",
                        }}
                      >
                        {vehicleType}
                      </span>
                    </h5>

                    <div className="de_form de_radio row g-3">
                      {vehicleCat?.map((vehicle, i) => (
                        <div
                          className="radio-img col-lg-4 col-sm-4 col-6"
                          key={i}
                        >
                          <input
                            id={`radio-${i}`}
                            name="Car_Type"
                            type="radio"
                            value={vehicle?.catName}
                            onChange={(e) => {
                              setVehicleType(e.target.value);
                            }}
                          />
                          <label htmlFor={`radio-${i}`}>
                            <img
                              src={vehicle?.catImg}
                              alt="Fleet car Chauffeur NYC"
                              style={{
                                width: "80%",
                                display: "block",
                                margin: "0 auto",
                              }}
                            />
                            {vehicle?.catName}
                          </label>
                        </div>
                      ))}
                    </div>

                    <div className="spacer-20"></div>

                    <div className="de_form de_radio row g-3">
                      {deliveryMethods?.map((method, i) => (
                        <div
                          className="radio-img col-lg-6 col-sm-6 col-6"
                          key={i}
                          style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid #1ecb15",
                              borderRadius: 4,
                              padding: 4,
                              paddingLeft: 8,
                              cursor: "pointer",
                              fontWeight: "500",
                              display: "flex",
                              flexDirection: "row",
                              gap: 8,
                              backgroundColor:
                                rideType?.title == method?.title
                                  ? "#1ecb15"
                                  : "#fff",
                            }}
                            onClick={() => {
                              setRideType(method);
                            }}
                          >
                            <i
                              className="fa fa-map-pin fa-md"
                              style={{
                                color:
                                  rideType?.title == method?.title
                                    ? "#fff"
                                    : "#1ecb15",
                                paddingTop: 5,
                              }}
                            ></i>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  color:
                                    rideType?.title == method?.title
                                      ? "#fff"
                                      : "#1ecb15",
                                }}
                              >
                                {method?.title}
                              </span>
                              <span
                                style={{
                                  color:
                                    rideType?.title == method?.title
                                      ? "#fff"
                                      : "#1ecb15",
                                  fontSize: 12,
                                  marginTop: 0,
                                  lineHeight: 1,
                                }}
                              >
                                {method?.turnaround}
                              </span>
                              <span
                                style={{
                                  color:
                                    rideType?.title == method?.title
                                      ? "#fff"
                                      : "#1ecb15",
                                  fontSize: 14,
                                  fontWeight: 800,
                                  marginTop: 5,
                                }}
                              >
                                {method?.price}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="spacer-20"></div>

                    <div className="row">
                      <div className="col-lg-6 mb20">
                        <h5>Pick Up Location</h5>
                        <LoadScript
                          googleMapsApiKey="AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI"
                          libraries={libraries}
                        >
                          <StandaloneSearchBox
                            onLoad={(ref) => (searchBoxPickUpRef.current = ref)}
                            onPlacesChanged={handlePickUpPlaceChanged}
                          >
                            <input
                              type="text"
                              placeholder="Enter address"
                              value={pickUpAddress}
                              className="form-control"
                              onChange={(e) => setPickUpAddress(e.target.value)}
                              style={{
                                boxSizing: `border-box`,
                                border: `1px solid #031b4e80`,
                                width: `100%`,
                                height: `40px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                fontSize: `16px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                              }}
                            />
                          </StandaloneSearchBox>
                        </LoadScript>

                        <div className="jls-address-preview jls-address-preview--hidden">
                          <div className="jls-address-preview__header"></div>
                        </div>
                      </div>
                      <div
                        className={
                          rideType?.title === "Hourly"
                            ? `col-lg-6 mb20`
                            : `d-none`
                        }
                      >
                        <h5>Booking for hours (min 3hrs.)</h5>
                        <div className="mt-2">
                          <input
                            id="rideHours"
                            type="number"
                            name="rideHours"
                            value={rideHours}
                            onChange={(e) => {
                              setRideHours(e.target.value);
                            }}
                            placeholder="Ride hours"
                            style={{
                              boxSizing: `border-box`,
                              border: `1px solid #031b4e80`,
                              width: `100%`,
                              height: `40px`,
                              padding: `0 12px`,
                              borderRadius: `3px`,
                              fontSize: `16px`,
                              outline: `none`,
                              textOverflow: `ellipses`,
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className={
                          rideType?.title === "Point to point"
                            ? `col-lg-6 mb20`
                            : `d-none`
                        }
                      >
                        <h5>Drop Off Location</h5>
                        <LoadScript
                          googleMapsApiKey="AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI"
                          libraries={libraries}
                        >
                          <StandaloneSearchBox
                            onLoad={(ref) =>
                              (searchBoxDropOffRef.current = ref)
                            }
                            onPlacesChanged={handleDropOffPlaceChanged}
                          >
                            <input
                              type="text"
                              placeholder="Enter address"
                              value={dropOffAddress}
                              className="form-control"
                              onChange={(e) =>
                                setDropOffAddress(e.target.value)
                              }
                              style={{
                                boxSizing: `border-box`,
                                border: `1px solid #031b4e80`,
                                width: `100%`,
                                height: `40px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                fontSize: `16px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                              }}
                            />
                          </StandaloneSearchBox>
                        </LoadScript>

                        <div className="jls-address-preview jls-address-preview--hidden">
                          <div className="jls-address-preview__header"></div>
                        </div>
                      </div>

                      <div className="col-lg-12 mb20">
                        <h5>Pick Up Date & Time</h5>
                        <div className="date-time-field">
                          <input
                            type="date"
                            name="Pick Up Date"
                            defaultValue={showDate}
                            style={{
                              fontWeight: 400,
                              lineHeight: 1.6,
                              border: "1px solid #031b4e80",
                              borderRadius: "3px",
                              height: "40px",
                              width: "48%",
                              paddingLeft: 12,
                              color: "#4f4f4f",
                            }}
                            ref={dateInputRef}
                            onClick={handleClick}
                            onChange={(e) => {
                              const [year, month, day] =
                                e.target.value.split("-");
                              const date = new Date(year, month - 1, day);
                              const formattedDate = date.toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "2-digit",
                                }
                              );
                              setShowDate(e.target.value);
                              setPickUpDate(formattedDate);
                            }}
                          />

                          <select
                            name="Pick Up Time"
                            id="pickup-time"
                            style={{
                              fontWeight: 400,
                              lineHeight: 1.6,
                              border: "1px solid #031b4e80",
                              borderRadius: "3px",
                              height: "40px",
                              width: "48%",
                              paddingLeft: 12,
                              color: "#4f4f4f",
                            }}
                            onChange={(e) => {
                              setPickupTime(e.target.value);
                            }}
                          >
                            <option value="Select time">Time</option>
                            <option value="00:00 AM">00:00 AM</option>
                            <option value="00:30 AM">00:30 AM</option>
                            <option value="01:00 AM">01:00 AM</option>
                            <option value="01:30 AM">01:30 AM</option>
                            <option value="02:00 AM">02:00 AM</option>
                            <option value="02:30 AM">02:30 AM</option>
                            <option value="03:00 AM">03:00 AM</option>
                            <option value="03:30 AM">03:30 AM</option>
                            <option value="04:00 AM">04:00 AM</option>
                            <option value="04:30 AM">04:30 AM</option>
                            <option value="05:00 AM">05:00 AM</option>
                            <option value="05:30 AM">05:30 AM</option>
                            <option value="06:00 AM">06:00 AM</option>
                            <option value="06:30 AM">06:30 AM</option>
                            <option value="07:00 AM">07:00 AM</option>
                            <option value="07:30 AM">07:30 AM</option>
                            <option value="08:00 AM">08:00 AM</option>
                            <option value="08:30 AM">08:30 AM</option>
                            <option value="09:00 AM">09:00 AM</option>
                            <option value="09:30 AM">09:30 AM</option>
                            <option value="10:00 AM">10:00 AM</option>
                            <option value="10:30 AM">10:30 AM</option>
                            <option value="11:00 AM">11:00 AM</option>
                            <option value="11:30 AM">11:30 AM</option>
                            <option value="12:00 PM">12:00 PM</option>
                            <option value="12:30 PM">12:30 PM</option>
                            <option value="01:00 PM">01:00 PM</option>
                            <option value="01:30 PM">01:30 PM</option>
                            <option value="02:00 PM">02:00 PM</option>
                            <option value="02:30 PM">02:30 PM</option>
                            <option value="03:00 PM">03:00 PM</option>
                            <option value="03:30 PM">03:30 PM</option>
                            <option value="04:00 PM">04:00 PM</option>
                            <option value="04:30 PM">04:30 PM</option>
                            <option value="05:00 PM">05:00 PM</option>
                            <option value="05:30 PM">05:30 PM</option>
                            <option value="06:00 PM">06:00 PM</option>
                            <option value="06:30 PM">06:30 PM</option>
                            <option value="07:00 PM">07:00 PM</option>
                            <option value="07:30 PM">07:30 PM</option>
                            <option value="08:00 PM">08:00 PM</option>
                            <option value="08:30 PM">08:30 PM</option>
                            <option value="09:00 PM">09:00 PM</option>
                            <option value="09:30 PM">09:30 PM</option>
                            <option value="10:00 PM">10:00 PM</option>
                            <option value="10:30 PM">11:30 PM</option>
                            <option value="11:00 PM">11:00 PM</option>
                            <option value="11:30 PM">12:30 PM</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={handleRideData}
                      className="btn-main pull-right"
                    >
                      Find Ride
                    </button>

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="position-absolute d-flex bottom-20">
            <div className="de-marquee-list style-2">
              <div className="d-item">
                <span className="d-item-txt">SUV</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Hatchback</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Crossover</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Convertible</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Sedan</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Sports Car</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Coupe</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Minivan</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Station Wagon</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Truck</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Minivans</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Exotic Cars</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
              </div>
            </div>

            <div className="de-marquee-list style-2">
              <div className="d-item">
                <span className="d-item-txt">SUV</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Hatchback</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Crossover</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Convertible</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Sedan</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Sports Car</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Coupe</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Minivan</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Station Wagon</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Truck</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Minivans</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
                <span className="d-item-txt">Exotic Cars</span>
                <span className="d-item-display">
                  <i className="d-item-dot"></i>
                </span>
              </div>
            </div>
          </div> */}
        </section>

        <section aria-label="section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h2>Our Flat Prices</h2>
                <p>
                  Discover a world of convenience, safety, and customization,
                  paving the way for unforgettable adventures and seamless
                  mobility solutions.
                </p>
                <div className="spacer-20"></div>
              </div>
              <div className="clearfix"></div>
              <div className="col-lg-3">
                <div
                  className="box-icon s2 p-small mb20 wow fadeInRight"
                  data-wow-delay=".5s"
                >
                  <i className="fa bg-color fa-map-pin"></i>
                  <div className="d-inner">
                    <h4>Manhattan to JFK International Airport</h4>
                    Sedan: $90 <br />
                    Minivan: $100 <br />
                    SUV: $130
                  </div>
                </div>
                <div
                  className="box-icon s2 p-small mb20 wow fadeInL fadeInRight"
                  data-wow-delay=".75s"
                >
                  <i className="fa bg-color fa-map-pin"></i>
                  <div className="d-inner">
                    <h4>Manhattan to LaGuardia Airport</h4>
                    Sedan: $60 <br />
                    Minivan: $80 <br />
                    SUV: $90
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <img
                  src="images/misc/car.png"
                  alt="Commercial SUV Chauffeur New York"
                  className="img-fluid wow fadeInUp"
                />
              </div>

              <div className="col-lg-3">
                <div
                  className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
                  data-wow-delay="1s"
                >
                  <i className="fa bg-color fa-map-pin"></i>
                  <div className="d-inner">
                    <h4>Manhattan to Newark Liberty International Airport</h4>
                    Sedan: $90 <br />
                    Minivan: $100 <br />
                    SUV: $130
                  </div>
                </div>
                <div
                  className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
                  data-wow-delay="1.25s"
                >
                  <i className="fa bg-color fa-map-pin"></i>
                  <div className="d-inner">
                    <h4>Hourly Prices</h4>
                    Sedan: $70 <br />
                    SUV: $90
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="text-light jarallax">
          <img
            src="images/background/2.jpg"
            className="jarallax-img"
            alt="Business car Chauffeur New York"
          />
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-6 wow fadeInRight">
                <h2>
                  We offer customers a wide range of&nbsp;
                  <span className="id-color">commercial cars&nbsp;</span>{" "}
                  and&nbsp;
                  <span className="id-color">luxury cars&nbsp;</span> for
                  travelling.
                </h2>
              </div>
              <div className="col-lg-6 wow fadeInLeft">
                At our agency, we believe that everyone deserves to experience
                the pleasure of a reliable and comfortable vehicle, regardless
                of their budget. We have curated a diverse fleet of
                well-maintained cars, ranging from sleek sedans to spacious
                SUVs, all at competitive prices. With our streamlined booking
                process, you can quickly and conveniently reserve your pick-up
                and drop-off. Whether you need transportation for a business
                trip, family vacation, or simply want to enjoy a weekend
                getaway, we have flexible options to accommodate your schedule.
              </div>
            </div>
            <div className="spacer-double"></div>
            <div className="row text-center">
              <div className="col-md-3 col-sm-6 mb-sm-30">
                <div className="de_count transparent text-light wow fadeInUp">
                  <h3 className="timer" data-to="15425" data-speed="3000">
                    0
                  </h3>
                  Completed Orders
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-sm-30">
                <div className="de_count transparent text-light wow fadeInUp">
                  <h3 className="timer" data-to="13653" data-speed="3000">
                    0
                  </h3>
                  Happy Customers
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-sm-30">
                <div className="de_count transparent text-light wow fadeInUp">
                  <h3 className="timer" data-to="235" data-speed="3000">
                    0
                  </h3>
                  Vehicles Fleet
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-sm-30">
                <div className="de_count transparent text-light wow fadeInUp">
                  <h3 className="timer" data-to="15" data-speed="3000">
                    0
                  </h3>
                  Years Experience
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-cars">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h2>Our Vehicle Fleet</h2>
                <p>
                  Driving your dreams to reality with an exquisite fleet of
                  versatile vehicles for unforgettable journeys.
                </p>
                <div className="spacer-20"></div>
              </div>

              <div id="items-carousel" className="row">
                {vehicles?.map((veh, i) => {
                  return (
                    <div className="col-lg-4" key={i}>
                      <div className="de-item mb30">
                        <div className="d-img">
                          <img
                            src={veh?.img}
                            className="img-fluid"
                            alt="Car hire New York"
                          />
                        </div>
                        <div className="d-info">
                          <div className="d-text">
                            <h4>{veh?.name}</h4>

                            <div className="d-atr-group">
                              <span className="d-atr">
                                <img
                                  src="images/icons/4-green.svg"
                                  alt="Car hire New York"
                                />
                                {veh?.cat}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <section className="text-light jarallax" aria-label="section">
          <img
            src="images/background/3.jpg"
            alt="Travel car Chauffeur New York"
            className="jarallax-img"
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-light">
                <div className="container-timeline">
                  <ul>
                    <li>
                      <h4>Choose a vehicle type</h4>
                      <p>
                        Select from a variety of premium vehicles tailored to
                        meet your travel needs. Enjoy comfort, style, and
                        reliability with every ride.
                      </p>
                    </li>
                    <li>
                      <h4>Pick location &amp; date</h4>
                      <p>
                        Specify your pickup location and preferred date for a
                        seamless, on-time service. We ensure prompt arrivals at
                        your convenience.
                      </p>
                    </li>
                    <li>
                      <h4>Make a booking</h4>
                      <p>
                        Confirm your booking with ease for a reliable,
                        stress-free experience. Enjoy streamlined scheduling
                        tailored to your travel plans.
                      </p>
                    </li>
                    <li>
                      <h4>Sit back &amp; relax</h4>
                      <p>
                        Sit back and enjoy the journey while we handle the rest.
                        Experience comfort and peace of mind with our
                        professional service.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="section-news">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h2>Latest News</h2>
                <p>
                  Breaking news, fresh perspectives, and in-depth coverage -
                  stay ahead with our latest news, insights, and analysis.
                </p>
                <div className="spacer-20"></div>
              </div>

              <div className="col-lg-4 mb10">
                <div className="bloglist s2 item">
                  <div className="post-content">
                    <div className="post-image">
                      <div className="date-box">
                        <div className="m">10</div>
                        <div className="d">MAR</div>
                      </div>
                      <img
                        alt=""
                        src="images/news/pic-blog-1.jpg"
                        className="lazy"
                      />
                    </div>
                    <div className="post-text">
                      <h4>
                        <a href="news-single.html">
                          Enjoy Best Travel Experience<span></span>
                        </a>
                      </h4>
                      <p>
                        Dolore officia sint incididunt non excepteur ea mollit
                        commodo ut enim reprehenderit cupidatat labore ad
                        laborum consectetur.
                      </p>
                      <a className="btn-main" href="#">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb10">
                <div className="bloglist s2 item">
                  <div className="post-content">
                    <div className="post-image">
                      <div className="date-box">
                        <div className="m">12</div>
                        <div className="d">MAR</div>
                      </div>
                      <img
                        alt=""
                        src="images/news/pic-blog-2.jpg"
                        className="lazy"
                      />
                    </div>
                    <div className="post-text">
                      <h4>
                        <a href="news-single.html">
                          The Future of Car Rent<span></span>
                        </a>
                      </h4>
                      <p>
                        Dolore officia sint incididunt non excepteur ea mollit
                        commodo ut enim reprehenderit cupidatat labore ad
                        laborum consectetur.
                      </p>
                      <a className="btn-main" href="#">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb10">
                <div className="bloglist s2 item">
                  <div className="post-content">
                    <div className="post-image">
                      <div className="date-box">
                        <div className="m">14</div>
                        <div className="d">MAR</div>
                      </div>
                      <img
                        alt=""
                        src="images/news/pic-blog-3.jpg"
                        className="lazy"
                      />
                    </div>
                    <div className="post-text">
                      <h4>
                        <a href="news-single.html">
                          Holiday Tips For Backpacker<span></span>
                        </a>
                      </h4>
                      <p>
                        Dolore officia sint incididunt non excepteur ea mollit
                        commodo ut enim reprehenderit cupidatat labore ad
                        laborum consectetur.
                      </p>
                      <a className="btn-main" href="news-single.html">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section id="section-faq">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <h2>Have Any Questions?</h2>
                <div className="spacer-20"></div>
              </div>
            </div>
            <div className="row g-custom-x">
              <div className="col-md-12 wow fadeInUp">
                <div className="accordion secondary">
                  <div className="accordion-section">
                    {faqs.map((faq, i) => (
                      <div key={i}>
                        <div
                          className="accordion-section-title"
                          data-tab={`#accordion-${i}`}
                        >
                          {faq?.faqQ}
                        </div>
                        <div
                          className="accordion-section-content"
                          style={{ display: "block" }}
                          id={`#accordion-${i}`}
                        >
                          <p>{faq?.faqA}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-testimonials" className="no-top no-bottom">
          <div className="container-fluid">
            <div className="row g-2 p-2 align-items-center">
              <div className="col-md-4">
                <div className="de-image-text">
                  <div className="d-text">
                    <div className="d-quote id-color">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    <h4>Excellent Service! Car Rent Service!</h4>
                    <blockquote>
                      I have been using Orienty Black Car Service for my
                      travelling needs for over 5 years now. I have never had
                      any problems with their service. Their customer support is
                      always responsive and helpful. I would recommend Orienty
                      Black Car Service to anyone looking for a reliable Car
                      Booking provider.
                      <span className="by">Stepanie Hutchkiss</span>
                    </blockquote>
                  </div>
                  <img
                    src="images/testimonial/1.jpg"
                    className="img-fluid"
                    alt="Travel car Chauffeur New York"
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="de-image-text">
                  <div className="d-text">
                    <div className="d-quote id-color">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    <h4>Excellent Service! Car Rent Service!</h4>
                    <blockquote>
                      We have been using Orienty Black Car Service for our trips
                      needs for several years now and have always been happy
                      with their service. Their customer support is Excellent
                      Service! and they are always available to help with any
                      issues we have. Their prices are also very competitive.
                      <span className="by">Jovan Reels</span>
                    </blockquote>
                  </div>
                  <img
                    src="images/testimonial/2.jpg"
                    className="img-fluid"
                    alt="Travel car Chauffeur New York"
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="de-image-text">
                  <div className="d-text">
                    <div className="d-quote id-color">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    <h4>Excellent Service! Car Rent Service!</h4>
                    <blockquote>
                      Endorsed by industry experts, Orienty Black Car Service is
                      the Car booking solution you can trust. With years of
                      experience in the field, they provide fast and reliable
                      service.
                      <span className="by">Kanesha Keyton</span>
                    </blockquote>
                  </div>
                  <img
                    src="images/testimonial/3.jpg"
                    className="img-fluid"
                    alt="Commercial and luxury car Chauffeur New York"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <a href="#" id="back-to-top"></a>
      <div
        style={{
          position: "fixed",
          bottom: 20,
          left: 20,
          borderRadius: 8,
          backgroundColor: "#05a884",
          cursor: "pointer",
          zIndex: 9999999,
        }}
      >
        <a
          href="https://wa.me/16803466020"
          target="_blank"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            margin: 10,
          }}
        >
          <i
            className="fa fa-whatsapp fa-lg"
            style={{
              color: "#fff",
              fontSize: 30,
            }}
          ></i>
          <span
            style={{
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            WhatsApp
          </span>
        </a>
      </div>
      <Footer />
    </>
  );
}

export default Home;
