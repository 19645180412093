import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import CryptoJS from "crypto-js";
import Loading from "../Components/Loading";
import { Link, Outlet } from "react-router-dom";
import baseUrl from "../Helper/config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [path, setPath] = useState("dashboard");

  const [showAddVeh, setShowAddVeh] = useState(false);
  const [addNewVehData, setAddNewVehData] = useState({
    img: "",
    name: "",
    cat: "Sedan",
    description: "",
    specifications: [],
    features: [],
  });

  const [showSched, setShowSched] = useState(false);
  const [scheduledRide, setScheduledRide] = useState({});

  const [showDriverModal, setShowDriverModal] = useState(false);
  const [driverData, setDriverData] = useState({
    img: "",
    name: "",
    email: "",
    password: "",
    company: "",
    cars: [],
    active: true,
  });

  useEffect(() => {
    var storedUsername = JSON.parse(
      localStorage.getItem("__OrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    }
    setLoading(true);

    // Dashboard Data
    var config = {
      method: "post",
      url: `${baseUrl}/v1/dashboardData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userDetails?.authToken || storedUsername?.authToken,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.allRides) {
          for (
            var i = 0;
            i < response?.data?.registeredDrivers?.data?.length;
            i++
          ) {
            const decrypted = CryptoJS.AES.decrypt(
              response?.data?.registeredDrivers?.data[i]?.password,
              process.env.REACT_APP_JWT_SECRET
            ).toString(CryptoJS.enc.Utf8);
            response.data.registeredDrivers.data[i].password = decrypted;
          }
          setDashboardData(response?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
    // Profile Data
    var config = {
      method: "post",
      url: `${baseUrl}/v1/userProfile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userDetails?.authToken || storedUsername?.authToken,
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.success) {
          const decrypted = CryptoJS.AES.decrypt(
            response?.data?.password,
            process.env.REACT_APP_JWT_SECRET
          ).toString(CryptoJS.enc.Utf8);
          setUserDetails({ ...response?.data, password: decrypted });
        }
      })
      .catch((error) => {
        toast.warn("Error!! Please try again later.");
      });
  }, []);

  const updateData = () => {
    if (
      !userDetails?.name ||
      !userDetails?.email ||
      !userDetails?.contact ||
      !userDetails?.password
    ) {
      toast.warn("Please fill all the details.");
    } else {
      setLoading(true);
      const encrypted = CryptoJS.AES.encrypt(
        userDetails?.password,
        process.env.REACT_APP_JWT_SECRET
      ).toString();
      var updateData = {
        name: userDetails?.name,
        email: userDetails?.email,
        contact: userDetails?.contact,
        password: encrypted,
        img: userDetails?.img,
      };

      let config = {
        method: "patch",
        url: `${baseUrl}/v1/userProfile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: userDetails?.authToken,
        },
        data: updateData,
      };

      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          if (response?.data?.success == false) {
            toast.warn("Error!! Please try again later.");
          } else {
            localStorage.setItem(
              "__OrientyBlackCarService__",
              JSON.stringify(response?.data)
            );
            toast.success("Profile info is updated.");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.warn("Error!! Please try again later.");
        });
    }
  };

  const updateUser = (user) => {
    setLoading(true);
    var updateData = user;
    let config = {
      method: "patch",
      url: `${baseUrl}/v1/admin/updateUser`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userDetails?.authToken,
      },
      data: updateData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.success == false) {
          toast.warn("Error!! Please try again later.");
        } else {
          toast.success("User info is updated.");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
  };

  const deleteVehicle = (vehId, remFleetData) => {
    let userResponse = window?.confirm(
      "Do you want to delete this item? This can't be undone."
    );

    if (userResponse) {
      setLoading(true);
      let config = {
        method: "delete",
        url: `${baseUrl}/v1/admin/vehicle`,
        headers: {
          "Content-Type": "application/json",
          Authorization: userDetails?.authToken,
        },
        data: {
          vehId,
        },
      };
      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          if (response?.data?.success == false) {
            toast.warn("Error!! Please try again later.");
          } else {
            toast.success("Success!! Vehicle deleted.");
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.warn("Error!! Please try again later.");
        });
    }
  };

  const handleAddVehChange = (e) => {
    const { name, value } = e.target;
    setAddNewVehData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDriverDataChange = (e) => {
    const { name, value } = e.target;
    setDriverData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateRide = (ride) => {
    setLoading(true);
    delete ride?._id;
    var updateData = ride;
    let config = {
      method: "patch",
      url: `${baseUrl}/v1/admin/updateRide`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userDetails?.authToken,
      },
      data: updateData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.success == false) {
          toast.warn("Error!! Please try again later.");
        } else {
          toast.success("Ride status is updated.");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
  };

  useEffect(() => {
    document.title = "Exclusive Chauffeured Car in New York City";
  }, []);

  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        show={showAddVeh}
        onHide={() => {
          setShowAddVeh(false);
        }}
        backdrop="static" // Sets the backdrop to static
        keyboard={false} // Prevents closing on "Escape" key
        centered // Center the modal vertically
        scrollable
      >
        <Modal.Header closeButton>
          {addNewVehData?.vehId ? (
            <Modal.Title>Edit Vehicle</Modal.Title>
          ) : (
            <Modal.Title>Add Vehicle</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <img
              src={addNewVehData?.img}
              alt="Airport car Transport NYC"
              style={{
                margin: "0px auto",
                display: "block",
                width: "30vh",
              }}
            />
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Image Link:</label>
                <input
                  type="text"
                  name="img"
                  value={addNewVehData?.img}
                  onChange={handleAddVehChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Vehicle name:</label>
                <input
                  type="text"
                  name="name"
                  value={addNewVehData?.name}
                  onChange={handleAddVehChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Vehicle type:</label>
                <br />
                <select
                  name="cat"
                  value={addNewVehData?.cat}
                  onChange={handleAddVehChange}
                  className="form-control"
                >
                  <option value="Sedan">Sedan</option>
                  <option value="SUV">SUV</option>
                  <option value="Minivan">Minivan</option>
                </select>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Vehicle description:</label>
                <textarea
                  type="text"
                  name="description"
                  value={addNewVehData?.descriptions}
                  onChange={handleAddVehChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Vehicle price per mile: (USD)</label>
                <input
                  type="number"
                  name="perMilePrice"
                  value={addNewVehData?.perMilePrice}
                  onChange={handleAddVehChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Vehicle features: Bluetooth,Mediaplayer</label>
                <input
                  type="text"
                  name="features"
                  value={addNewVehData?.features?.join(",")}
                  onChange={(e) => {
                    setAddNewVehData((prevData) => ({
                      ...prevData,
                      features: e?.target?.value?.split(","),
                    }));
                  }}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Vehicle specifications:
                  <p
                    style={{
                      textDecoration: "underline",
                      marginBottom: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      addNewVehData?.specifications?.push({
                        name: "",
                        value: "",
                      });
                      setAddNewVehData((prevData) => ({
                        ...prevData,
                        specifications: addNewVehData?.specifications,
                      }));
                    }}
                  >
                    Add Specs
                  </p>
                </label>
                {addNewVehData?.specifications?.map((spec, i) => {
                  return (
                    <div className="row" key={i}>
                      <div className="col-md-6">
                        <label>Name:</label>
                        <input
                          type="text"
                          value={spec?.name}
                          className="form-control"
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            var updatedItems =
                              addNewVehData?.specifications?.map(
                                (item, index) => {
                                  if (index == i) {
                                    return {
                                      ...item,
                                      name: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                            setAddNewVehData({
                              ...addNewVehData,
                              specifications: updatedItems,
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Value:</label>
                        <input
                          type="text"
                          value={spec?.value}
                          className="form-control"
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            var updatedItems =
                              addNewVehData?.specifications?.map(
                                (item, index) => {
                                  if (index == i) {
                                    return {
                                      ...item,
                                      value: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                            setAddNewVehData({
                              ...addNewVehData,
                              specifications: updatedItems,
                            });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
        {addNewVehData?.vehId ? (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                delete addNewVehData?._id;
                setLoading(true);
                var config = {
                  method: "patch",
                  url: `${baseUrl}/v1/admin/vehicle`,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: userDetails?.authToken,
                  },
                  data: addNewVehData,
                };
                axios
                  .request(config)
                  .then((response) => {
                    setLoading(false);
                    if (response?.data?.success) {
                      toast.success("Success!! Vehicle info is updated.");
                      setShowAddVeh(false);
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    toast.warn("Error!! Please try again later.");
                  });
              }}
            >
              Update
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setLoading(true);
                var config = {
                  method: "post",
                  url: `${baseUrl}/v1/admin/vehicle`,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: userDetails?.authToken,
                  },
                  data: addNewVehData,
                };
                axios
                  .request(config)
                  .then((response) => {
                    setLoading(false);
                    if (response?.data?.success) {
                      toast.success("Success!! Vehicle added to fleet.");
                      setShowAddVeh(false);
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    toast.warn("Error!! Please try again later.");
                  });
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <Modal
        show={showSched}
        onHide={() => {
          setShowSched(false);
        }}
        size="xl"
        backdrop="static" // Sets the backdrop to static
        keyboard={false} // Prevents closing on "Escape" key
        centered // Center the modal vertically
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Ride Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section
            style={{
              padding: 0,
            }}
          >
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-6">
                  <div className="item">
                    <img
                      src={scheduledRide?.carData?.img}
                      alt="Wedding car NYC"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                  <h3 className="mt-4">{scheduledRide?.carData?.name}</h3>
                  <p>{scheduledRide?.carData?.descriptions}</p>

                  <div className="spacer-10"></div>

                  <h4>Specifications</h4>
                  <div className="de-spec">
                    {scheduledRide?.carData?.specifications?.length > 0
                      ? scheduledRide?.carData?.specifications?.map(
                          (spec, i) => {
                            return (
                              <div className="d-row" key={i}>
                                <span className="d-title">{spec?.name}</span>
                                <span className="d-value">{spec?.value}</span>
                              </div>
                            );
                          }
                        )
                      : null}
                  </div>

                  <div className="spacer-single"></div>

                  <h4>Features</h4>
                  <ul className="ul-style-2">
                    {scheduledRide?.carData?.features?.length > 0
                      ? scheduledRide?.carData?.features?.map((feat, i) => {
                          return <li key={i}>{feat}</li>;
                        })
                      : null}
                  </ul>
                </div>

                <div className="col-lg-6">
                  {userDetails?.role == "ADMIN" ||
                  userDetails?.role == "DRIVER" ? (
                    <div className="col-md-12">
                      <div className="field-set">
                        <label>Set Status:</label>
                        <br />
                        <select
                          value={scheduledRide?.status}
                          onChange={(e) => {
                            setScheduledRide({
                              ...scheduledRide,
                              status: e.target.value,
                            });
                            updateRide({
                              ...scheduledRide,
                              status: e.target.value,
                            });
                          }}
                          className="form-control"
                        >
                          <option value="scheduled">Scheduled</option>
                          <option value="confirmed">Confirmed</option>
                          <option value="completed">Completed</option>
                          <option value="cancelled">Cancelled</option>
                        </select>
                      </div>
                    </div>
                  ) : null}

                  {userDetails?.role == "ADMIN" ? (
                    <div className="col-md-12">
                      <div className="field-set">
                        <label>Assign Driver:</label>
                        <br />
                        <select
                          disabled={
                            scheduledRide?.status == "scheduled" ? false : true
                          }
                          value={scheduledRide?.assignedDriver?.driverId}
                          onChange={(e) => {
                            if (e?.target?.value == "") {
                              setScheduledRide({
                                ...scheduledRide,
                                assignedDriver: {},
                              });
                              updateRide({
                                ...scheduledRide,
                                assignedDriver: {},
                              });
                            } else {
                              var updateUserData =
                                dashboardData?.registeredDrivers?.data?.filter(
                                  (item) => item?.driverId == e?.target?.value
                                );

                              setScheduledRide({
                                ...scheduledRide,
                                assignedDriver: updateUserData[0],
                              });
                              updateRide({
                                ...scheduledRide,
                                assignedDriver: updateUserData[0],
                              });
                            }
                          }}
                          className="form-control"
                        >
                          <option value="">Select Driver</option>
                          {dashboardData?.registeredDrivers?.data?.map(
                            (driver, i) => {
                              return (
                                <option value={driver?.driverId} key={i}>
                                  {driver?.name}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {userDetails?.role == "ADMIN" ||
                  userDetails?.role == "USER" ? (
                    <div className="de-price text-center mt-4">
                      Total Price Paid
                      <h3>${Number(scheduledRide?.amount / 100)}</h3>
                      {scheduledRide?.taxCharged ? (
                        <p
                          style={{
                            fontSize: 12,
                          }}
                        >
                          Tax Charged: ${scheduledRide?.taxCharged}
                        </p>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="spacer-30"></div>
                  <div className="de-box mb25">
                    <div name="contactForm" id="contact_form" method="post">
                      <h4>Booking this ride</h4>

                      <div className="spacer-20"></div>

                      <div className="row">
                        <div className="col-lg-12">
                          <h5>Pick Up Location</h5>
                          <p>{scheduledRide?.rideData?.pickUpAddress}</p>
                        </div>

                        <div className="col-lg-12">
                          <h5>Drop Off Location</h5>
                          <p>{scheduledRide?.rideData?.dropOffAddress}</p>
                        </div>

                        <div className="col-lg-6">
                          <h5>Pick Up Date</h5>
                          <div className="date-time-field">
                            <p>{scheduledRide?.rideData?.pickUpDate}</p>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <h5>Pick Up Time</h5>
                          <div className="date-time-field">
                            <p>{scheduledRide?.rideData?.pickUpTime}</p>
                          </div>
                        </div>
                      </div>
                      <h4
                        style={{
                          borderTop: "1px solid #ddd",
                          paddingTop: 10,
                          marginBottom: 0,
                        }}
                      >
                        User Details
                      </h4>
                      <div className="spacer-20"></div>
                      <div className="row">
                        <div className="col-lg-12">
                          <h5>Name</h5>
                          <p>{scheduledRide?.userDetails?.name}</p>
                        </div>
                        <div className="col-lg-12">
                          <h5>Email</h5>
                          <p>{scheduledRide?.userDetails?.email}</p>
                        </div>
                        <div className="col-lg-12">
                          <h5>Phone</h5>
                          <p>{scheduledRide?.userDetails?.phone}</p>
                        </div>
                      </div>

                      <h4
                        style={{
                          borderTop: "1px solid #ddd",
                          paddingTop: 10,
                          marginBottom: 0,
                        }}
                      >
                        Additional Details
                      </h4>
                      <div className="spacer-20"></div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h5>No. of Passengers</h5>
                          <p>
                            {
                              scheduledRide?.additionalDetails
                                ?.numberOfPassengers
                            }
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <h5>No. of Baggage</h5>
                          <p>
                            {scheduledRide?.additionalDetails?.numberOfBaggage}
                          </p>
                        </div>
                        {scheduledRide?.additionalDetails?.flightNumber && (
                          <div className="col-lg-12">
                            <h5>Flight Number</h5>
                            <p>
                              {scheduledRide?.additionalDetails?.flightNumber}
                            </p>
                          </div>
                        )}

                        {scheduledRide?.additionalDetails
                          ?.additionalDetailsText && (
                          <div className="col-lg-12">
                            <h5>Additional Details</h5>
                            <p>
                              {
                                scheduledRide?.additionalDetails
                                  ?.additionalDetailsText
                              }
                            </p>
                          </div>
                        )}
                      </div>

                      <h4
                        style={{
                          borderTop: "1px solid #ddd",
                          paddingTop: 10,
                          marginBottom: 0,
                        }}
                      >
                        Additional Seats Details
                      </h4>
                      <div className="spacer-20"></div>
                      <div className="row">
                        <div className="col-lg-12">
                          <h5>No. of Booster Seats needed (4-5 Years Old)</h5>
                          <p>
                            {scheduledRide?.additionalSeatsDetails?.boosterSeat}
                          </p>
                        </div>
                        <div className="col-lg-12">
                          <h5>No. of Child Seats needed (1-3 Years Old)</h5>
                          <p>
                            {scheduledRide?.additionalSeatsDetails?.childSeat}
                          </p>
                        </div>
                        <div className="col-lg-12">
                          <h5>
                            No. of Infant Seats needed (16 months or younger)
                          </h5>
                          <p>
                            {scheduledRide?.additionalSeatsDetails?.infantSeat}
                          </p>
                        </div>
                      </div>

                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDriverModal}
        onHide={() => {
          setShowDriverModal(false);
        }}
        backdrop="static" // Sets the backdrop to static
        keyboard={false} // Prevents closing on "Escape" key
        centered // Center the modal vertically
        scrollable
      >
        <Modal.Header closeButton>
          {driverData?.driverId ? (
            <Modal.Title>Edit Driver</Modal.Title>
          ) : (
            <Modal.Title>Add Driver</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <img
              src={`https://api.dicebear.com/9.x/initials/svg?seed=${driverData?.name}`}
              alt="Road trip Chauffeur car NYC"
              style={{
                margin: "0px auto",
                display: "block",
                width: "20vh",
              }}
            />
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={driverData?.name}
                  onChange={handleDriverDataChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Email:</label>
                <input
                  type="text"
                  name="email"
                  value={driverData?.email}
                  onChange={handleDriverDataChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Password:</label>
                <input
                  type="text"
                  name="password"
                  value={driverData?.password}
                  onChange={handleDriverDataChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Company:</label>
                <input
                  type="text"
                  name="company"
                  value={driverData?.company}
                  onChange={handleDriverDataChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Cars</label>
                <input
                  type="text"
                  name="cars"
                  value={driverData?.cars?.join(",")}
                  onChange={(e) => {
                    setDriverData((prevData) => ({
                      ...prevData,
                      cars: e?.target?.value?.split(","),
                    }));
                  }}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="field-set">
                <label>Status</label>
                <select
                  value={driverData?.active}
                  className="form-control"
                  style={{
                    fontWeight: 400,
                    border: "1px solid #031b4e80",
                    borderRadius: "3px",
                    color: "#4f4f4f",
                  }}
                  onChange={(e) => {
                    setDriverData((prevData) => ({
                      ...prevData,
                      active: e.target.value.toLowerCase() === "true",
                    }));
                  }}
                >
                  <option value={true}>Active</option>
                  <option value={false}>In-Active</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        {driverData?.driverId ? (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                const encrypted = CryptoJS.AES.encrypt(
                  driverData?.password,
                  process.env.REACT_APP_JWT_SECRET
                ).toString();
                delete driverData?._id;
                driverData.img = `https://api.dicebear.com/9.x/initials/svg?seed=${driverData?.name}`;
                setLoading(true);
                var config = {
                  method: "patch",
                  url: `${baseUrl}/v1/admin/driver`,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: userDetails?.authToken,
                  },
                  data: { ...driverData, password: encrypted },
                };
                axios
                  .request(config)
                  .then((response) => {
                    setLoading(false);
                    if (response?.data?.success) {
                      toast.success("Success!! Driver info is updated.");
                      setShowDriverModal(false);
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    toast.warn("Error!! Please try again later.");
                  });
              }}
            >
              Update
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                const encrypted = CryptoJS.AES.encrypt(
                  driverData?.password,
                  process.env.REACT_APP_JWT_SECRET
                ).toString();
                driverData.img = `https://api.dicebear.com/9.x/initials/svg?seed=${driverData?.name}`;
                setLoading(true);
                var config = {
                  method: "post",
                  url: `${baseUrl}/v1/admin/driver`,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: userDetails?.authToken,
                  },
                  data: { ...driverData, password: encrypted },
                };
                axios
                  .request(config)
                  .then((response) => {
                    setLoading(false);
                    if (response?.data?.success) {
                      toast.success("Success!! Driver added to fleet.");
                      setShowDriverModal(false);
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    toast.warn("Error!! Please try again later.");
                  });
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <Header />
      <div className="no-bottom no-top zebra" id="content">
        <div id="top"></div>

        <section id="subheader" className="jarallax text-light">
          <img
            src="images/background/14.jpg"
            className="jarallax-img"
            alt="Chauffeured luxury cars New York"
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Dashboard</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-cars" className="bg-gray-100">
          <div
            className="container-fluid"
            style={{
              padding: "0 20px",
            }}
          >
            <div className="row">
              <div className="col-lg-3 mb30">
                <div
                  className="card p-4 rounded-5"
                  style={{
                    position: "relative",
                    border:
                      userDetails?.role == "ADMIN"
                        ? "1px solid #1fcb14"
                        : "none",
                  }}
                >
                  {userDetails?.role == "ADMIN" ? (
                    <p
                      style={{
                        position: "absolute",
                        width: "100%",
                        padding: 0,
                        color: "#fff",
                        background: "#1fcb14",
                        top: 0,
                        left: 0,
                        right: 0,
                        margin: 0,
                        fontSize: 12,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      ADMIN
                    </p>
                  ) : null}

                  <div className="profile_avatar mt-2">
                    <div className="profile_img">
                      <img
                        src={userDetails?.img}
                        alt="Luxury Chauffeur SUV New York"
                      />
                    </div>
                    <div className="profile_name">
                      <h4>
                        {userDetails?.name}
                        <span className="profile_username text-gray">
                          {userDetails?.email}
                        </span>
                      </h4>
                    </div>
                  </div>
                  <div className="spacer-20"></div>
                  <ul className="menu-col">
                    <li
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <a
                        onClick={() => {
                          setPath("dashboard");
                        }}
                      >
                        <i className="fa fa-home"></i>
                        Dashboard
                      </a>
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <a
                        onClick={() => {
                          setPath("profile");
                        }}
                      >
                        <i className="fa fa-user"></i>
                        My Profile
                      </a>
                    </li>
                    {userDetails?.role == "ADMIN" ? (
                      <>
                        <li
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <a
                            onClick={() => {
                              setPath("users");
                            }}
                          >
                            <i className="fa fa-users"></i>
                            Users
                          </a>
                        </li>
                        <li
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <a
                            onClick={() => {
                              setPath("vehicles");
                            }}
                          >
                            <i className="fa fa-car"></i>
                            Vehicles
                          </a>
                        </li>
                        <li
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <a
                            onClick={() => {
                              setPath("drivers");
                            }}
                          >
                            <i className="fa fa-black-tie"></i>
                            Drivers
                          </a>
                        </li>
                      </>
                    ) : null}

                    <li
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <a
                        onClick={() => {
                          setPath("rides");
                        }}
                      >
                        <i className="fa fa-calendar"></i>
                        {userDetails?.role == "ADMIN"
                          ? "All Rides"
                          : "My Rides"}
                      </a>
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setLoading(true);
                        setTimeout(() => {
                          setLoading(false);
                          localStorage.removeItem("__OrientyBlackCarService__");
                          navigate("/");
                          window.location.reload();
                        }, 1000);
                      }}
                    >
                      <Link>
                        <i className="fa fa-sign-out"></i>
                        Sign Out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {path == "dashboard" && (
                <div className="col-lg-9">
                  {userDetails?.role == "ADMIN" ? (
                    <div className="row">
                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-car"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.vehiclesFleet?.totalCount}
                          </span>
                          <span className="text-gray">Vehicles</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-users"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.registeredUsers?.totalCount}
                          </span>
                          <span className="text-gray">Registred Users</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-black-tie"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.registeredDrivers?.totalCount}
                          </span>
                          <span className="text-gray">Drivers</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.allRides?.totalCount}
                          </span>
                          <span className="text-gray">All Rides</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.scheduledRides?.totalCount}
                          </span>
                          <span className="text-gray">Scheduled Rides</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar-plus-o"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.confirmedRides?.totalCount}
                          </span>
                          <span className="text-gray">Confirmed Rides</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar-check-o"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.completedRides?.totalCount}
                          </span>
                          <span className="text-gray">Completed Rides</span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar-minus-o"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.cancelledRides?.totalCount}
                          </span>
                          <span className="text-gray">Cancelled Rides</span>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {userDetails?.role == "USER" ? (
                    <div className="row">
                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar-check-o"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.scheduledRides?.totalCount}
                          </span>
                          <span className="text-gray">Scheduled Rides</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-tags"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.confirmedRides?.totalCount}
                          </span>
                          <span className="text-gray">Confirmed Rides</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.completedRides?.totalCount}
                          </span>
                          <span className="text-gray">Completed Rides</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar-times-o"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.cancelledRides?.totalCount}
                          </span>
                          <span className="text-gray">Cancelled Rides</span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {userDetails?.role == "DRIVER" ? (
                    <div className="row">
                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar-check-o"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.allRides?.totalCount}
                          </span>
                          <span className="text-gray">All Rides</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-tags"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.confirmedRides?.totalCount}
                          </span>
                          <span className="text-gray">Confirmed Rides</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.completedRides?.totalCount}
                          </span>
                          <span className="text-gray">Completed Rides</span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6 mb25 order-sm-1">
                        <div className="card p-4 rounded-5">
                          <div className="symbol mb40">
                            <i className="fa id-color fa-2x fa-calendar-times-o"></i>
                          </div>
                          <span className="h1 mb0">
                            {dashboardData?.cancelledRides?.totalCount}
                          </span>
                          <span className="text-gray">Cancelled Rides</span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {userDetails?.role != "ADMIN" ? (
                    <div className="card p-4 rounded-5 mb25">
                      <h4>
                        {userDetails?.role == "DRIVER"
                          ? "All Rides"
                          : "My Rides"}
                      </h4>

                      <table className="table de-table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <span className="text-uppercase fs-12 text-gray">
                                Order ID
                              </span>
                            </th>
                            <th scope="col">
                              <span className="text-uppercase fs-12 text-gray">
                                Car Name
                              </span>
                            </th>

                            <th scope="col">
                              <span className="text-uppercase fs-12 text-gray">
                                Pick Up Location
                              </span>
                            </th>
                            <th scope="col">
                              <span className="text-uppercase fs-12 text-gray">
                                Drop Off Location
                              </span>
                            </th>
                            <th scope="col">
                              <span className="text-uppercase fs-12 text-gray">
                                Pick Up Date
                              </span>
                            </th>
                            <th scope="col">
                              <span className="text-uppercase fs-12 text-gray">
                                Pick Up Time
                              </span>
                            </th>
                            <th scope="col">
                              <span className="text-uppercase fs-12 text-gray">
                                Status
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dashboardData?.allRides?.data?.length > 0 ? (
                            dashboardData?.allRides?.data?.map((ride, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Order ID
                                    </span>
                                    <div className="badge bg-gray-100 text-dark">
                                      {ride?.orderNumber}
                                    </div>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Car Name
                                    </span>
                                    <span className="bold">
                                      {ride?.carData?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Location
                                    </span>
                                    <p>{ride?.rideData?.pickUpAddress}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Drop Off Location
                                    </span>
                                    <p>{ride?.rideData?.dropOffAddress}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Date
                                    </span>
                                    <p>{ride?.rideData?.pickUpDate}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Time
                                    </span>
                                    {ride?.rideData?.pickUpTime}
                                  </td>
                                  <td>
                                    {ride?.status == "scheduled" ? (
                                      <div className="badge rounded-pill bg-warning">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : ride?.status == "confirmed" ||
                                      ride?.status == "completed" ? (
                                      <div className="badge rounded-pill bg-success">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : ride?.status == "cancelled" ? (
                                      <div className="badge rounded-pill bg-danger">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : null}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>
                                <span>No Data!!</span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              )}
              {path == "users" && userDetails?.role == "ADMIN" ? (
                <div className="col-lg-9">
                  <div className="card p-4 rounded-5 mb25">
                    <h4>All Users</h4>

                    <table className="table de-table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Date
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Name
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Email
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Role
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Status
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Action
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.registeredUsers?.data?.length > 0 ? (
                          dashboardData?.registeredUsers?.data?.map(
                            (ride, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Date
                                    </span>
                                    <div className="badge bg-gray-100 text-dark">
                                      {moment
                                        .unix(ride?.createdDate)
                                        .format("MMM DD,YYYY")}
                                    </div>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Name
                                    </span>
                                    <p className="bold">{ride?.name}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Email
                                    </span>
                                    <p>{ride?.email}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Role
                                    </span>
                                    <p>{ride?.role}</p>
                                  </td>
                                  <td>
                                    {ride?.active ? (
                                      <div className="badge rounded-pill bg-success">
                                        ACTIVE
                                      </div>
                                    ) : (
                                      <div className="badge rounded-pill bg-danger">
                                        IN-ACTIVE
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Actions
                                    </span>
                                    <select
                                      value={ride?.active}
                                      style={{
                                        fontWeight: 400,
                                        border: "1px solid #031b4e80",
                                        borderRadius: "3px",
                                        height: "30px",
                                        color: "#4f4f4f",
                                      }}
                                      onChange={(e) => {
                                        var updatedItems =
                                          dashboardData?.registeredUsers?.data?.map(
                                            (item) => {
                                              if (item.email === ride?.email) {
                                                return {
                                                  ...item,
                                                  active:
                                                    e.target.value.toLowerCase() ===
                                                    "true",
                                                }; // Update the name
                                              }
                                              return item;
                                            }
                                          );
                                        setDashboardData({
                                          ...dashboardData,
                                          registeredUsers: {
                                            ...dashboardData?.registeredUsers,
                                            data: updatedItems,
                                          },
                                        });

                                        var updateUserData =
                                          updatedItems?.filter(
                                            (item) =>
                                              item?.email === ride?.email
                                          );
                                        updateUser(updateUserData[0]);
                                      }}
                                    >
                                      <option value={true}>Active</option>
                                      <option value={false}>In-Active</option>
                                    </select>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td>
                              <span>No Data!!</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
              {path == "drivers" && userDetails?.role == "ADMIN" ? (
                <div className="col-lg-9">
                  <div className="card p-4 rounded-5 mb25">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4
                        style={{
                          margin: 0,
                        }}
                      >
                        All Drivers
                      </h4>
                      <button
                        className="btn-main"
                        onClick={() => {
                          setShowDriverModal(true);
                          setDriverData({
                            img: "",
                            name: "",
                            email: "",
                            password: "",
                            company: "",
                            cars: [],
                            active: true,
                          });
                        }}
                      >
                        Add Driver
                      </button>
                    </div>

                    <table className="table de-table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Date
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Name
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Email
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Role
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Status
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Action
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.registeredDrivers?.data?.length > 0 ? (
                          dashboardData?.registeredDrivers?.data?.map(
                            (ride, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Date
                                    </span>
                                    <div className="badge bg-gray-100 text-dark">
                                      {moment
                                        .unix(ride?.createdDate)
                                        .format("MMM DD,YYYY")}
                                    </div>
                                  </td>
                                  <td>
                                    <p className="bold">{ride?.name}</p>
                                  </td>
                                  <td>
                                    <p>{ride?.email}</p>
                                  </td>
                                  <td>
                                    <p>{ride?.role}</p>
                                  </td>
                                  <td>
                                    {ride?.active ? (
                                      <div className="badge rounded-pill bg-success">
                                        ACTIVE
                                      </div>
                                    ) : (
                                      <div className="badge rounded-pill bg-danger">
                                        IN-ACTIVE
                                      </div>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <i
                                      className="fa fa-edit"
                                      style={{
                                        color: "#1fcb14",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setShowDriverModal(true);
                                        setDriverData(ride);
                                      }}
                                    ></i>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td>
                              <span>No Data!!</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
              {path == "vehicles" && userDetails?.role == "ADMIN" ? (
                <div className="col-lg-9">
                  <div className="card p-4 rounded-5 mb25">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4
                        style={{
                          margin: 0,
                        }}
                      >
                        All Vehicles
                      </h4>
                      <button
                        className="btn-main"
                        onClick={() => {
                          setShowAddVeh(true);
                          setAddNewVehData({
                            img: "",
                            name: "",
                            cat: "Sedan",
                            description: "",
                            specifications: [],
                            features: [],
                          });
                        }}
                      >
                        Add Vehicle
                      </button>
                    </div>

                    <table className="table de-table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Date
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Name
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Type
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Price per mile
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Specifications
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Actions
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.vehiclesFleet?.data?.length > 0 ? (
                          dashboardData?.vehiclesFleet?.data?.map((ride, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <div className="badge bg-gray-100 text-dark">
                                    {moment
                                      .unix(ride?.createdDate)
                                      .format("MMM DD,YYYY")}
                                  </div>
                                </td>
                                <td>
                                  <p className="bold">{ride?.name}</p>
                                </td>
                                <td>
                                  <p>{ride?.cat?.toUpperCase()}</p>
                                </td>
                                <td>
                                  <p>${ride?.perMilePrice}</p>
                                </td>
                                <td>{ride?.specifications?.length} Specs.</td>
                                <td>
                                  <i
                                    className="fa fa-edit"
                                    style={{
                                      marginRight: 10,
                                      color: "#1fcb14",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowAddVeh(true);
                                      setAddNewVehData(ride);
                                    }}
                                  ></i>
                                  <i
                                    className="fa fa-trash"
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const fleetData =
                                        dashboardData?.vehiclesFleet?.data.filter(
                                          (_, index) => index === i
                                        );
                                      const remFleetData =
                                        dashboardData?.vehiclesFleet?.data.filter(
                                          (_, index) => index !== i
                                        );

                                      deleteVehicle(
                                        fleetData[0]?.vehId,
                                        remFleetData
                                      );
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>
                              <span>No Data!!</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
              {path == "profile" && (
                <div className="col-lg-9">
                  <div className="card p-4 rounded-5">
                    <div className="row">
                      <div className="col-lg-12">
                        <div id="form-create-item" className="form-border">
                          <div className="de_tab tab_simple">
                            <ul className="de_nav">
                              <li className="active">
                                <span>Profile</span>
                              </li>
                            </ul>

                            <div className="de_tab_content">
                              <div className="tab-1">
                                <div className="row">
                                  <div className="col-lg-6 mb20">
                                    <h5>Name</h5>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter name"
                                      value={userDetails?.name}
                                      onChange={(e) => {
                                        setUserDetails({
                                          ...userDetails,
                                          name: e?.target?.value,
                                          img: `https://api.dicebear.com/9.x/initials/svg?seed=${e?.target?.value}`,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-6 mb20">
                                    <h5>Email</h5>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter email"
                                      value={userDetails?.email}
                                      onChange={(e) => {
                                        setUserDetails({
                                          ...userDetails,
                                          email: e?.target?.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-6 mb20">
                                    <h5>Phone</h5>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Phone Contact"
                                      value={userDetails?.phone}
                                      onChange={(e) => {
                                        setUserDetails({
                                          ...userDetails,
                                          phone: e?.target?.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-6 mb20">
                                    <h5>Password</h5>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder="Enter Password"
                                      value={userDetails?.password}
                                      onChange={(e) => {
                                        setUserDetails({
                                          ...userDetails,
                                          password: e?.target?.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <button
                            id="submit"
                            className="btn-main"
                            onClick={updateData}
                          >
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {path == "rides" && (
                <div className="col-lg-9">
                  <div className="card p-4 rounded-5 mb25">
                    <h4>Scheduled Rides</h4>

                    <table className="table de-table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Order ID
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Car Name
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Location
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Drop Off Location
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Date
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Time
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Status
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Action
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.scheduledRides?.data?.length > 0 ? (
                          dashboardData?.scheduledRides?.data?.map(
                            (ride, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Order ID
                                    </span>
                                    <div className="badge bg-gray-100 text-dark">
                                      {ride?.orderNumber}
                                    </div>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Car Name
                                    </span>
                                    <span className="bold">
                                      {ride?.carData?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Location
                                    </span>
                                    <p>{ride?.rideData?.pickUpAddress}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Drop Off Location
                                    </span>
                                    <p>{ride?.rideData?.dropOffAddress}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Date
                                    </span>
                                    <p>{ride?.rideData?.pickUpDate}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Time
                                    </span>
                                    {ride?.rideData?.pickUpTime}
                                  </td>
                                  <td>
                                    {ride?.status == "scheduled" ? (
                                      <div className="badge rounded-pill bg-warning">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : ride?.status == "confirmed" ||
                                      ride?.status == "completed" ? (
                                      <div className="badge rounded-pill bg-success">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : ride?.status == "cancelled" ? (
                                      <div className="badge rounded-pill bg-danger">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit"
                                      style={{
                                        color: "#1fcb14",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setScheduledRide(ride);
                                        setShowSched(true);
                                      }}
                                    ></i>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td>
                              <span>No Data!!</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="card p-4 rounded-5 mb25">
                    <h4>Confirmed Orders</h4>

                    <table className="table de-table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Order ID
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Car Name
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Location
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Drop Off Location
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Date
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Time
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Status
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Action
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.confirmedRides?.data?.length > 0 ? (
                          dashboardData?.confirmedRides?.data?.map(
                            (ride, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Order ID
                                    </span>
                                    <div className="badge bg-gray-100 text-dark">
                                      {ride?.orderNumber}
                                    </div>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Car Name
                                    </span>
                                    <span className="bold">
                                      {ride?.carData?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Location
                                    </span>
                                    <p>{ride?.rideData?.pickUpAddress}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Drop Off Location
                                    </span>
                                    <p>{ride?.rideData?.dropOffAddress}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Date
                                    </span>
                                    <p>{ride?.rideData?.pickUpDate}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Time
                                    </span>
                                    {ride?.rideData?.pickUpTime}
                                  </td>
                                  <td>
                                    {ride?.status == "scheduled" ? (
                                      <div className="badge rounded-pill bg-warning">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : ride?.status == "confirmed" ||
                                      ride?.status == "completed" ? (
                                      <div className="badge rounded-pill bg-success">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : ride?.status == "cancelled" ? (
                                      <div className="badge rounded-pill bg-danger">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit"
                                      style={{
                                        color: "#1fcb14",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setScheduledRide(ride);
                                        setShowSched(true);
                                      }}
                                    ></i>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td>
                              <span>No Data!!</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="card p-4 rounded-5 mb25">
                    <h4>Completed Rides</h4>

                    <table className="table de-table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Order ID
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Car Name
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Location
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Drop Off Location
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Date
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Time
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Status
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Action
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.completedRides?.data?.length > 0 ? (
                          dashboardData?.completedRides?.data?.map(
                            (ride, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Order ID
                                    </span>
                                    <div className="badge bg-gray-100 text-dark">
                                      {ride?.orderNumber}
                                    </div>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Car Name
                                    </span>
                                    <span className="bold">
                                      {ride?.carData?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Location
                                    </span>
                                    <p>{ride?.rideData?.pickUpAddress}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Drop Off Location
                                    </span>
                                    <p>{ride?.rideData?.dropOffAddress}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Date
                                    </span>
                                    <p>{ride?.rideData?.pickUpDate}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Time
                                    </span>
                                    {ride?.rideData?.pickUpTime}
                                  </td>
                                  <td>
                                    {ride?.status == "scheduled" ? (
                                      <div className="badge rounded-pill bg-warning">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : ride?.status == "confirmed" ||
                                      ride?.status == "completed" ? (
                                      <div className="badge rounded-pill bg-success">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : ride?.status == "cancelled" ? (
                                      <div className="badge rounded-pill bg-danger">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit"
                                      style={{
                                        color: "#1fcb14",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setScheduledRide(ride);
                                        setShowSched(true);
                                      }}
                                    ></i>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td>
                              <span>No Data!!</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="card p-4 rounded-5 mb25">
                    <h4>Cancelled Rides</h4>

                    <table className="table de-table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Order ID
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Car Name
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Location
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Drop Off Location
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Date
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Pick Up Time
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Status
                            </span>
                          </th>
                          <th scope="col">
                            <span className="text-uppercase fs-12 text-gray">
                              Action
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.cancelledRides?.data?.length > 0 ? (
                          dashboardData?.cancelledRides?.data?.map(
                            (ride, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Order ID
                                    </span>
                                    <div className="badge bg-gray-100 text-dark">
                                      {ride?.orderNumber}
                                    </div>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Car Name
                                    </span>
                                    <span className="bold">
                                      {ride?.carData?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Location
                                    </span>
                                    <p>{ride?.rideData?.pickUpAddress}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Drop Off Location
                                    </span>
                                    <p>{ride?.rideData?.dropOffAddress}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Date
                                    </span>
                                    <p>{ride?.rideData?.pickUpDate}</p>
                                  </td>
                                  <td>
                                    <span className="d-lg-none d-sm-block">
                                      Pick Up Time
                                    </span>
                                    {ride?.rideData?.pickUpTime}
                                  </td>
                                  <td>
                                    {ride?.status == "scheduled" ? (
                                      <div className="badge rounded-pill bg-warning">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : ride?.status == "confirmed" ||
                                      ride?.status == "completed" ? (
                                      <div className="badge rounded-pill bg-success">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : ride?.status == "cancelled" ? (
                                      <div className="badge rounded-pill bg-danger">
                                        {ride?.status?.toUpperCase()}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit"
                                      style={{
                                        color: "#1fcb14",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setScheduledRide(ride);
                                        setShowSched(true);
                                      }}
                                    ></i>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td>
                              <span>No Data!!</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer />
    </>
  );
};

export default Dashboard;
