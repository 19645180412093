import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function Privacy() {
  useEffect(() => {
    document.title = "Professional Chauffeured Transportation in NYC";
  }, []);
  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        <section id="subheader" className="jarallax text-light">
          <img
            src="images/background/subheader.jpg"
            className="jarallax-img"
            alt="Premium transportation New York City"
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Privacy Policy</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section aria-label="section">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h3>Privacy Policy</h3>
                <p>Last Updated: November 13, 2024</p>

                <h4>1. Information We Collect</h4>
                <p>
                  We collect information from you when you use our Services,
                  including:
                </p>
                <ul>
                  <li>
                    <strong>Personal Information</strong>
                  </li>
                  <ul>
                    <li>
                      <strong>Account Information:</strong> When you create an
                      Orienty Black Car Service account, we collect your name,
                      email address, phone number, and payment information (such
                      as credit card details or PayPal).
                    </li>
                    <li>
                      <strong>Profile Information:</strong> If you choose to add
                      a profile photo, vehicle details, or other personal
                      information to your account, we collect and store that
                      information.
                    </li>
                    <li>
                      <strong>Transaction Information:</strong> We collect
                      details of the services you request, including the date,
                      time, and location of your ride or delivery, as well as
                      the transaction amount.
                    </li>
                  </ul>
                  <li>
                    <strong>Payment Information</strong>
                  </li>
                  <ul>
                    <li>
                      <strong>Credit Card Information:</strong> When you add a
                      payment method, we collect and store payment information,
                      such as credit or debit card details. This data is
                      securely transmitted and stored through our third-party
                      payment processors in compliance with Payment Card
                      Industry Data Security Standard (PCI DSS).
                    </li>
                    <li>
                      <strong>Tokenization:</strong> We do not store your full
                      credit card information. Instead, your payment information
                      is tokenized and securely stored with our payment
                      processors to facilitate future transactions.
                    </li>
                  </ul>
                  <li>
                    <strong>Location Data</strong>
                  </li>
                  <ul>
                    <li>
                      <strong>Location Information:</strong> When you use
                      Orienty Black Car Service, we collect real-time location
                      data to help match you with drivers, provide ride
                      tracking, and calculate trip fares. This includes GPS data
                      from your mobile device and location-related information
                      when using the app.
                    </li>
                    <ul>
                      <li>
                        <strong>For drivers:</strong> We collect location data
                        while the app is in use to provide location-based
                        services, match riders with drivers, and help with
                        navigation.
                      </li>
                      <li>
                        <strong>For riders:</strong> We collect your location
                        data when the app is in use to match you with the
                        nearest available driver and for route navigation.
                      </li>
                    </ul>
                  </ul>
                  <li>
                    <strong>Usage Data</strong>
                  </li>
                  <ul>
                    <li>
                      <strong>Device Information:</strong> We collect
                      information about the device you use to access our
                      Services, including device type, operating system, mobile
                      carrier, and unique device identifiers.
                    </li>
                    <li>
                      <strong>App Activity:</strong> We may collect data on how
                      you interact with our app, including features used, search
                      queries, and settings adjustments.
                    </li>
                  </ul>
                  <li>
                    <strong>Communication Data</strong>
                  </li>
                  <ul>
                    <li>
                      <strong>Customer Support:</strong> If you contact Orienty
                      Black Car Service customer support, we collect the content
                      of your communication, along with any additional data you
                      provide to resolve your query or issue.
                    </li>
                  </ul>
                </ul>

                <h4>2. How We Use Your Information</h4>
                <p>
                  We use the information we collect for various purposes,
                  including:
                </p>
                <ul>
                  <li>To Provide and Improve Our Services</li>
                  <li>Payment Processing</li>
                  <li>Customer Support</li>
                  <li>Personalization</li>
                  <li>Marketing and Promotions</li>
                  <li>Compliance and Legal Obligations</li>
                </ul>

                <h4>3. Sharing Your Information</h4>
                <p>We may share your information in the following ways:</p>
                <ul>
                  <li>With Drivers and Riders</li>
                  <li>With Service Providers</li>
                  <li>With Third-Party Integrations</li>
                  <li>For Legal Compliance</li>
                  <li>Business Transfers</li>
                </ul>

                <h4>4. Security of Your Information</h4>
                <p>
                  We take the security of your information seriously and use
                  industry-standard security measures to protect it. This
                  includes encryption, secure storage, and access control to
                  prevent unauthorized access or disclosure of your data.
                </p>

                <h4>5. Your Rights and Choices</h4>
                <ul>
                  <li>Access</li>
                  <li>Update or Correct</li>
                  <li>Delete</li>
                  <li>Opt-Out of Marketing</li>
                </ul>

                <h4>6. Retention of Your Data</h4>
                <p>
                  We retain your personal information only for as long as
                  necessary to fulfill the purposes outlined in this Privacy
                  Policy, including for legal, accounting, or reporting
                  requirements.
                </p>

                <h4>7. Children's Privacy</h4>
                <p>
                  Our Services are not intended for individuals under the age of
                  18, and we do not knowingly collect or solicit personal
                  information from children.
                </p>

                <h4>8. Changes to This Privacy Policy</h4>
                <p>
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or for other operational, legal, or
                  regulatory reasons.
                </p>

                <h4>9. Contact Us</h4>
                <p>
                  If you have any questions about this Privacy Policy or how we
                  handle your personal data, please contact us:
                </p>
                <p>
                  Orienty Black Car Service
                  <br />
                  <a href="mailto:contact@orientyblackcarservice.com">
                    contact@orientyblackcarservice.com
                  </a>
                  <br />
                  +1 (680) 346-6020
                </p>

                <div className="spacer-10"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer />
    </>
  );
}

export default Privacy;
