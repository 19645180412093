import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function Terms() {
  useEffect(() => {
    document.title =
      "New York City Chauffeured Car Services for Business & Events";
  }, []);
  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>

        <section id="subheader" className="jarallax text-light">
          <img
            src="images/background/subheader.jpg"
            className="jarallax-img"
            alt="Professional drivers in New York City"
          />
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Terms & Conditions</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section aria-label="section">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h3>Terms and Conditions for Orienty Black Car Service</h3>
                <p>Last Updated: November 13, 2024</p>
                <p>
                  Welcome to <strong>Orienty Black Car Service</strong>. By
                  using our services, you agree to comply with and be bound by
                  the following terms and conditions. Please read them carefully
                  before making a booking or using our services.
                </p>

                <h4>1. General Agreement</h4>
                <p>
                  By booking or using Orienty Black Car Service, you acknowledge
                  that you have read, understood, and agreed to be bound by
                  these terms and conditions, which may be updated from time to
                  time without notice. It is your responsibility to review these
                  terms periodically for any updates.
                </p>

                <h4>2. Service Availability</h4>
                <p>
                  Orienty Black Car Service operates 24/7, subject to vehicle
                  and driver availability. While we strive to meet all requests,
                  we reserve the right to decline service due to unforeseen
                  circumstances, including weather conditions, traffic, or
                  vehicle maintenance.
                </p>

                <h4>3. Bookings and Payments</h4>
                <ul>
                  <li>
                    <strong>Booking Process:</strong> All bookings must be
                    confirmed via our website, mobile app, or customer service
                    hotline. A confirmation email or message will be sent once
                    the booking is confirmed.
                  </li>
                  <li>
                    <strong>Rates:</strong> Fare rates depend on the type of
                    vehicle, distance, duration, and additional services
                    requested will have separate charges. Rates will be
                    disclosed at the time of booking and include applicable
                    taxes and tolls unless otherwise stated.
                  </li>
                  <li>
                    <strong>Payment:</strong> Payment must be made via credit
                    card, debit card, or approved payment method at the time of
                    booking. For corporate accounts, monthly invoicing may be
                    arranged upon approval. Any changes to the route, waiting
                    times, or additional requests may result in additional
                    charges.
                  </li>
                </ul>

                <h4>4. Cancellation and Refund Policy</h4>
                <ul>
                  <li>
                    <strong>Cancellation Policy:</strong> Cancellations must be
                    made at least three hours prior to the scheduled pick-up
                    time without any penalty. Cancellations made less than 3
                    hours before the service will incur a cancellation fee of
                    50% of the total fare.
                  </li>
                  <li>
                    <strong>No-Shows:</strong> If you do not show up at the
                    designated pick-up location without prior notice, you will
                    be charged the full fare for the ride.
                  </li>
                  <li>
                    <strong>Refund Policy:</strong> Refunds will be processed
                    for cancellations made in accordance with our cancellation
                    policy. Refunds for other issues will be considered on a
                    case-by-case basis and issued within 3-5 business days if
                    approved.
                  </li>
                </ul>

                <h4>5. Passenger Conduct</h4>
                <p>
                  Passengers are expected to behave in a respectful and orderly
                  manner throughout the ride. The following are strictly
                  prohibited:
                </p>
                <ul>
                  <li>Smoking in the vehicle</li>
                  <li>Consumption of alcohol or illegal substances</li>
                  <li>Inappropriate or disruptive behavior</li>
                </ul>
                <p>
                  If the driver deems that the passenger's conduct is unsafe or
                  unacceptable, they have the right to terminate the service
                  without any refund.
                </p>

                <h4>6. Luggage and Personal Belongings</h4>
                <ul>
                  <li>
                    <strong>Luggage Allowance:</strong> Orienty Black Car
                    Service accommodates reasonable amounts of luggage based on
                    the vehicle type. Passengers are encouraged to inform us of
                    any oversized or excessive luggage during booking.
                  </li>
                  <li>
                    <strong>Lost Property:</strong> We are not liable for any
                    personal belongings left in the vehicle. However, if you
                    notify us immediately after the trip, we will make
                    reasonable efforts to recover and return lost items.
                  </li>
                </ul>

                <h4>7. Safety and Insurance</h4>
                <p>
                  Your safety is our top priority. All our vehicles are
                  well-maintained and regularly inspected. Our drivers are fully
                  licensed, trained, and insured. However, Orienty Black Car
                  Service is not liable for any delays or incidents caused by
                  factors beyond our control, including traffic, weather
                  conditions, or mechanical breakdowns.
                </p>

                <h4>8. Limitations of Liability</h4>
                <ul>
                  <li>
                    We are not responsible for delays caused by unforeseen
                    circumstances such as traffic, road closures, or adverse
                    weather.
                  </li>
                  <li>
                    In the event of vehicle breakdowns, we will attempt to
                    arrange an alternative vehicle as soon as possible. However,
                    we do not guarantee any specific time frame for replacement
                    service.
                  </li>
                  <li>
                    Our liability is limited to the total fare paid for the
                    ride. We are not liable for any indirect, incidental, or
                    consequential damages resulting from the use of our service.
                  </li>
                </ul>

                <h4>9. Privacy and Data Protection</h4>
                <p>
                  We respect your privacy and are committed to protecting your
                  personal information. Any data collected during booking or
                  service will be used solely for the purpose of providing and
                  improving our services. Your information will not be shared
                  with third parties without your consent, except where required
                  by law.
                </p>

                <h4>10. Force Majeure</h4>
                <p>
                  Orienty Black Car Service shall not be held liable for any
                  delay or failure to perform its obligations under these terms
                  due to events beyond our control, including natural disasters,
                  strikes, pandemics, or government restrictions.
                </p>

                <h4>11. Governing Law</h4>
                <p>
                  These terms and conditions are governed by the laws of New
                  York, USA. Any disputes or claims arising out of or relating
                  to the use of our service shall be subject to the exclusive
                  jurisdiction of the courts in New York, USA.
                </p>

                <h4>12. Changes to Terms and Conditions</h4>
                <p>
                  Orienty Black Car Service reserves the right to modify or
                  update these terms and conditions at any time. Any changes
                  will be effective immediately upon posting to our website.
                  Your continued use of our services following the posting of
                  changes constitutes your acceptance of the updated terms.
                </p>

                <h4>13. Contact Us</h4>
                <p>
                  If you have any questions, concerns, or feedback about these
                  terms and conditions, please contact us:
                </p>

                <p>
                  Orienty Black Car Service
                  <br />
                  +1(347)894-7091
                  <br />
                  +1(680)346-6020
                  <br />
                  contact@orientyblackcarservice.com
                  <br />
                </p>

                <div class="suggestion">
                  <p class="suggestion-title">
                    Vague Service Availability Clause:
                  </p>
                  <p>
                    The service operates "24/7, subject to vehicle and driver
                    availability" but doesn't specify how the business will
                    handle periods of non-availability. A failure to manage
                    customer expectations about availability could lead to
                    customer dissatisfaction or legal claims in case of frequent
                    disruptions.
                  </p>
                  <p>
                    <strong>Suggestion:</strong> Clarify how customers will be
                    notified of unavailability (e.g., via app updates, email)
                    and whether any compensation or alternatives will be
                    provided.
                  </p>
                </div>

                <div class="suggestion">
                  <p class="suggestion-title">Limitation of Liability:</p>
                  <p>
                    The limitation of liability restricts claims to "the total
                    fare paid," which may not fully protect the business in
                    cases of serious incidents, especially personal injury,
                    property damage, or breaches of data privacy.
                  </p>
                  <p>
                    <strong>Suggestion:</strong> Include specific disclaimers
                    for personal injury or property damage to further protect
                    the business. Consider adding a clause that caps liability
                    for data breaches if applicable (in line with privacy laws).
                  </p>
                </div>

                <div class="suggestion">
                  <p class="suggestion-title">
                    Ambiguous Force Majeure Clause:
                  </p>
                  <p>
                    The force majeure clause mentions events beyond control
                    (natural disasters, pandemics, strikes) but doesn’t detail
                    how customer refunds or cancellations will be handled during
                    such events. Without this, customers may demand full refunds
                    or compensation during unforeseen disruptions.
                  </p>
                  <p>
                    <strong>Suggestion:</strong> Clearly specify whether
                    customers are entitled to refunds or if services will be
                    rescheduled during a force majeure event. This protects both
                    the business and the customer during unavoidable delays.
                  </p>
                </div>

                <div class="suggestion">
                  <p class="suggestion-title">Refunds for No-Shows:</p>
                  <p>
                    The clause states that "no-shows" will be charged the full
                    fare. However, in some situations (e.g., due to unavoidable
                    delays by the customer), enforcing this without some
                    flexibility could lead to disputes.
                  </p>
                  <p>
                    <strong>Suggestion:</strong> Define what constitutes a valid
                    reason for no-shows (e.g., unavoidable emergencies) and
                    whether partial refunds might apply in certain situations.
                    This helps mitigate potential conflicts with customers.
                  </p>
                </div>

                <div class="suggestion">
                  <p class="suggestion-title">
                    Unclear Data Protection Policy:
                  </p>
                  <p>
                    The privacy clause states that personal data will not be
                    shared without consent, except when required by law.
                    However, this might not fully meet the requirements of
                    stricter data protection regulations, such as GDPR or the
                    CCPA, depending on the business’s customer base.
                  </p>
                  <p>
                    <strong>Suggestion:</strong> Ensure that the data protection
                    section fully complies with relevant privacy laws,
                    specifying the types of data collected, how long it is
                    stored, how it is protected, and whether customers can
                    request data deletion or access their information.
                  </p>
                </div>

                <div class="suggestion">
                  <p class="suggestion-title">
                    Termination for Passenger Conduct:
                  </p>
                  <p>
                    Drivers are allowed to terminate the ride for inappropriate
                    behavior without issuing a refund. While this is necessary
                    for safety, it could lead to customer complaints or legal
                    challenges if not applied consistently or if the passenger
                    feels it was done unjustly.
                  </p>
                  <p>
                    <strong>Suggestion:</strong> Consider implementing a process
                    to document such incidents (e.g., dashcams, driver reports)
                    to avoid potential legal claims. It could also be helpful to
                    include an appeal process for customers who believe the
                    termination was unfair.
                  </p>
                </div>

                <p>
                  By addressing these areas,{" "}
                  <strong>Orienty Black Car Service</strong> can further reduce
                  legal risks and customer disputes, while maintaining customer
                  trust and operational reliability.
                </p>

                <div className="spacer-10"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <a href="#" id="back-to-top"></a>
      <Footer />
    </>
  );
}

export default Terms;
