import React from "react";
import { Helmet } from "react-helmet";

const MetaDescription = ({ description }) => {
  return (
    <Helmet>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default MetaDescription;
