import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./Pages/home";
import Cars from "./Pages/cars";
import Car from "./Pages/car";
import Login from "./Pages/login";
import About from "./Pages/about";
import Register from "./Pages/register";
import Contact from "./Pages/contact";
import Dashboard from "./Pages/dashboard";
import ScrollToTop from "./Components/ScrollToTop";
import NotFound from "./Pages/404";
import Terms from "./Pages/terms";
import Privacy from "Pages/privacy";

import MetaDescription from "Components/MetaDescription";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <MetaDescription description="Experience premium chauffeured car services in New York City. Reliable & luxurious transportation for business, events and airport transfers. Book now for hassle-free travel." />
              <Home />
            </>
          }
        />
        <Route path="cars" element={<Cars />} />
        <Route path="car" element={<Car />} />
        <Route
          path="about"
          element={
            <>
              <MetaDescription description="Discover top-notch chauffeured car services in New York City. Reliable, luxurious, and professional transportation for business, events, airport transfers, and city tours. Book your ride today." />
              <About />
            </>
          }
        />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route
          path="contact"
          element={
            <>
              <MetaDescription description="Luxury chauffeured car in New York City for business, airport transfers, events, and city tours. Reliable transportation with professional drivers. Book your ride in NYC today." />
              <Contact />
            </>
          }
        />
        <Route
          path="login"
          element={
            <>
              <MetaDescription description="Experience premium chauffeured car in New York. Perfect for airport transfers, corporate travel, events, and city tours. Reliable and luxurious transportation in NYC. Book now." />
              <Login />
            </>
          }
        />
        <Route
          path="register"
          element={
            <>
              <MetaDescription description="Chauffeured car services in New York offering luxury, reliability, and convenience. Ideal for airport transfers, business travel, events, and NYC tours. Reserve your ride today." />
              <Register />
            </>
          }
        />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="dashboard-profile" element={<Dashboard />} />
        <Route path="dashboard-rides" element={<Dashboard />} />
        <Route path=":noPath" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
